import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import { API_ROUTE } from "../";
import { selectAuth } from "../redux/slices/authSlice";

import html2canvas from 'html2canvas';

import './styles/CreateAerialPage.css';
import { IconArrowLeft, IconFile, IconFileUpload, IconSearch, IconX } from "@tabler/icons-react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Blocks } from "react-loader-spinner";
import NavDropDown from "../components/NavDropDown";
import { ToastContainer, toast } from "react-toastify";

export default function CreateAerialPage() {
    const params = useParams();
    const projectUUID = params.projectUUID;

    const auth = useSelector(selectAuth)

    const [map, setMap] = useState(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const mapRef = useRef(null);

    const [box, setBox] = useState({
        top: 0.6 * window.innerWidth < 0.8 * window.innerHeight
            ? window.innerHeight / 2 - 0.3 * window.innerWidth
            : window.innerHeight / 2 - 0.4 * window.innerHeight,
        left: 0.6 * window.innerWidth < 0.8 * window.innerHeight
            ? window.innerWidth / 2 - 0.3 * window.innerWidth
            : window.innerWidth / 2 - 0.4 * window.innerHeight,
        width: Math.min(0.6 * window.innerWidth, 0.80 * window.innerHeight),
        height: Math.min(0.6 * window.innerWidth, 0.80 * window.innerHeight),
    });

    const [image, setImage] = useState(null);

    const [capturing, setCapturing] = useState(false);

    // Load Google Maps script dynamically
    useEffect(() => {
        const loadGoogleMapsScript = () => {
            const script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyApK64eSS3keysTqURXdFMgd7AQY1qpmpc&libraries=places';
            script.async = true;
            script.defer = true;
            script.onload = () => setMapLoaded(true);
            document.body.appendChild(script);
        };

        loadGoogleMapsScript();
    }, []);

    // Initialize map and set up Places search functionality
    const initializeMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 37.7749, lng: -122.4194 },
            zoom: 14,
            mapTypeId: 'satellite',
        });

        const input = document.getElementById('search-input');
        const searchBox = new window.google.maps.places.SearchBox(input);
        map.addListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds());
        });

        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();
            if (places.length === 0) return;

            const place = places[0];
            if (!place.geometry || !place.geometry.location) return;

            map.setCenter(place.geometry.location);
            map.setZoom(18);
        });

        setMap(map);
    };

    useEffect(() => {
        if (mapLoaded && !map) initializeMap();
    }, [mapLoaded, map]);

    const handleMouseDown = (corner) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        const startX = e.clientX;
        const startY = e.clientY;

        const initialBox = { ...box };

        const onMouseMove = (moveEvent) => {
            const dx = moveEvent.clientX - startX;
            const dy = moveEvent.clientY - startY;

            setBox((prevBox) => {
                switch (corner) {
                    case 'top-left':
                        return {
                            ...prevBox,
                            top: moveEvent.clientY,
                            left: moveEvent.clientX,
                            width: prevBox.left + prevBox.width - moveEvent.clientX,
                            height: prevBox.top + prevBox.height - moveEvent.clientY,
                        };
                    case 'top-right':
                        return {
                            ...prevBox,
                            top: moveEvent.clientY,
                            width: moveEvent.clientX - prevBox.left,
                            height: prevBox.top + prevBox.height - moveEvent.clientY,
                        };
                    case 'bottom-left':
                        return {
                            ...prevBox,
                            left: moveEvent.clientX,
                            width: prevBox.left + prevBox.width - moveEvent.clientX,
                            height: moveEvent.clientY - prevBox.top,
                        };
                    case 'bottom-right':
                        return {
                            ...prevBox,
                            width: moveEvent.clientX - prevBox.left,
                            height: moveEvent.clientY - prevBox.top,
                        };
                    default:
                        return prevBox;
                }
            });
        };

        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };

    /*
    const captureMap = () => {
        //use the bounding box to crop the image
        if (!mapRef.current) return;

        // Capture the map area using html2canvas
        html2canvas(mapRef.current, { useCORS: true }).then((canvas) => {
            // Get the coordinates and dimensions of the bounding box
            const { top, left, width, height } = box;

            // Create a new canvas to store the cropped image
            const croppedCanvas = document.createElement('canvas');
            croppedCanvas.width = width;
            croppedCanvas.height = height;

            const ctx = croppedCanvas.getContext('2d');

            // Draw the cropped area from the original canvas onto the new canvas
            ctx.drawImage(
                canvas,           // Source canvas
                left, top,        // Source x and y (top-left corner of the box)
                width, height,    // Source width and height (dimensions of the box)
                0, 0,             // Destination x and y (top-left of new canvas)
                width, height     // Destination width and height (full size of new canvas)
            );

            // Convert the cropped canvas to a data URL and download the image
            croppedCanvas.toBlob((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'map_snapshot.png';
                link.click();
            });
        });
    };
    */


    const captureMap = () => {
        if (!map) return;

        setCapturing(true);

        // Calculate the bounding box in latitude and longitude
        const mapBounds = map.getBounds();
        const mapCenter = map.getCenter();
        const mapZoom = map.getZoom();

        // Calculate the pixel dimensions of the box relative to the map
        const mapDiv = mapRef.current;
        const mapWidth = mapDiv.offsetWidth;
        const mapHeight = mapDiv.offsetHeight;

        const boxTopRatio = box.top / mapHeight;
        const boxLeftRatio = box.left / mapWidth;
        const boxBottomRatio = (box.top + box.height) / mapHeight;
        const boxRightRatio = (box.left + box.width) / mapWidth;

        // Get latitude and longitude for each corner of the bounding box
        const northEastLatLng = mapBounds.getNorthEast();
        const southWestLatLng = mapBounds.getSouthWest();

        const latDiff = northEastLatLng.lat() - southWestLatLng.lat();
        const lngDiff = northEastLatLng.lng() - southWestLatLng.lng();

        const boxNorth = northEastLatLng.lat() - latDiff * boxTopRatio;
        const boxSouth = northEastLatLng.lat() - latDiff * boxBottomRatio;
        const boxWest = southWestLatLng.lng() + lngDiff * boxLeftRatio;
        const boxEast = southWestLatLng.lng() + lngDiff * boxRightRatio;

        // Set up the Google Maps Static API URL with bounding box center and zoom level
        const boxCenterLat = (boxNorth + boxSouth) / 2;
        const boxCenterLng = (boxWest + boxEast) / 2;

        const width = 1280;
        const height = 1280;

        const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${boxCenterLat},${boxCenterLng}&zoom=${mapZoom}&size=${width}x${height}&scale=2&maptype=satellite&key=AIzaSyApK64eSS3keysTqURXdFMgd7AQY1qpmpc`;

        let metersPerPx = 156543.03392 * Math.cos(mapCenter.lat() * Math.PI / 180) / Math.pow(2, mapZoom)
        let feetPerPx = metersPerPx * 3.28084
        console.log('metersPerPx:', metersPerPx);
        console.log('feetPerPx:', feetPerPx);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/create-aerial-page/`,
            data: {
                projectUUID: projectUUID,
                title: 'Aerial Image',
                image_url: staticMapUrl,
                feet_per_px: feetPerPx / 2,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log('Aerial image uploaded:', response.data);

                setCapturing(false);

                toast.success(`Aerial image added to takeoff files!`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: null,
                    theme: "light",
                });
            })
            .catch((error) => {
                console.error('Error uploading the aerial image:', error);
            });
    };


    /*
    const captureMap = async () => {
        if (!map) return;

        // Calculate the bounding box in latitude and longitude
        const mapBounds = map.getBounds();
        const mapZoom = map.getZoom();

        const northEastLatLng = mapBounds.getNorthEast();
        const southWestLatLng = mapBounds.getSouthWest();
        const latDiff = northEastLatLng.lat() - southWestLatLng.lat();
        const lngDiff = northEastLatLng.lng() - southWestLatLng.lng();

        // Calculate box boundaries in lat/lng using screen coordinates
        const boxTopRatio = box.top / mapRef.current.offsetHeight;
        const boxLeftRatio = box.left / mapRef.current.offsetWidth;
        const boxBottomRatio = (box.top + box.height) / mapRef.current.offsetHeight;
        const boxRightRatio = (box.left + box.width) / mapRef.current.offsetWidth;

        const boxNorth = northEastLatLng.lat() - latDiff * boxTopRatio;
        const boxSouth = northEastLatLng.lat() - latDiff * boxBottomRatio;
        const boxWest = southWestLatLng.lng() + lngDiff * boxLeftRatio;
        const boxEast = southWestLatLng.lng() + lngDiff * boxRightRatio;

        // Define grid settings
        const TILE_SIZE = 1280; // Max size per Google Maps Static API request
        const ZOOM_LEVEL = Math.min(mapZoom + 1, 21); // Increase zoom level for more detail

        // Determine number of tiles required for high-resolution image
        const latStep = (boxNorth - boxSouth) / Math.ceil(box.height / TILE_SIZE);
        const lngStep = (boxEast - boxWest) / Math.ceil(box.width / TILE_SIZE);

        const tiles = [];

        for (let lat = boxNorth; lat > boxSouth; lat -= latStep) {
            for (let lng = boxWest; lng < boxEast; lng += lngStep) {
                const url = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${ZOOM_LEVEL}&scale=2&size=${TILE_SIZE}x${TILE_SIZE}&maptype=satellite&key=AIzaSyApK64eSS3keysTqURXdFMgd7AQY1qpmpc`;
                tiles.push({ url, lat, lng });
            }
        }

        // Fetch all tiles
        const images = await Promise.all(
            tiles.map((tile) =>
                fetch(tile.url)
                    .then((response) => response.blob())
                    .then((blob) => createImageBitmap(blob))
            )
        );

        console.log(images);

        // Create canvas to stitch tiles together
        const canvas = document.createElement('canvas');
        canvas.width = Math.ceil(box.width * (ZOOM_LEVEL / mapZoom));
        canvas.height = Math.ceil(box.height * (ZOOM_LEVEL / mapZoom));
        const ctx = canvas.getContext('2d');

        // Draw each tile onto the canvas
        let yOffset = 0;
        for (let row = 0; row < Math.ceil(box.height / TILE_SIZE); row++) {
            let xOffset = 0;
            for (let col = 0; col < Math.ceil(box.width / TILE_SIZE); col++) {
                const image = images[row * Math.ceil(box.width / TILE_SIZE) + col];
                ctx.drawImage(image, xOffset, yOffset, TILE_SIZE, TILE_SIZE);
                xOffset += TILE_SIZE;
            }
            yOffset += TILE_SIZE;
        }

        // Convert the canvas to a downloadable image
        canvas.toBlob((blob) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'high_resolution_map_snapshot.png';
            link.click();
        });
    };
    */

    return (
        <div className="createaerialpage-container">
            <ToastContainer
                className={'create-aerial-toastcontainer'}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="createaerialpage-header">
                <a href={'/files/' + projectUUID} className='createaerialpage-option-link'>
                    <div className='projectdetailsnavbar-back'>
                        <IconArrowLeft size={24} />
                        <div>
                            Takeoff Files
                        </div>
                    </div>
                </a>

                <div className="createaerialpage-header-search">
                    <div>
                        <IconSearch size={24} />
                    </div>

                    <PlacesAutocomplete
                        value={searchQuery}
                        onChange={(a) => setSearchQuery(a)}
                        onSelect={(a) => {
                            setSearchQuery(a);
                            //set map center and zoom level
                            if (map) {
                                const geocoder = new window.google.maps.Geocoder();
                                geocoder.geocode({ address: a }, (results, status) => {
                                    if (status === 'OK') {
                                        map.setCenter(results[0].geometry.location);
                                        map.setZoom(17);
                                    }
                                });
                            }
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                            <div className='createaerialpage-autocomplete-container'>
                                <input
                                    {...getInputProps({
                                        type: "text",
                                        name: "address",
                                        placeholder: "Project address...",
                                    })}
                                    className="createaerialpage-search-input"
                                ></input>

                                {suggestions.length > 0 &&
                                    <div className="createaerialpage-autocomplete-dropdown-container">
                                        {suggestions.map(suggestion => {
                                            console.log(suggestion);
                                            const className = suggestion.active ? 'projectdetails-suggestion-item-active' : 'projectdetails-suggestion-item';
                                            return (
                                                <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.description}>
                                                    {suggestion.description}
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </PlacesAutocomplete>

                    <div
                        className="createaerialpage-search-clear"
                        onClick={() => setSearchQuery('')}
                    >
                        <IconX size={20} />
                    </div>
                </div>
                {capturing
                    ? <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'right',
                            padding: '10px 20px',
                            gap: '10px',
                        }}
                    >
                        <div>Capturing...</div>
                        <Blocks
                            visible={true}
                            height="20"
                            width="20"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                    : <div className="createaerialpage-header-capture-button-conatiner">
                        <button onClick={captureMap} className="createaerialpage-header-capture-button">
                            Capture Snapshot
                        </button>
                    </div>
                }
            </div >
            <div
                ref={mapRef}
                className="createaerialpage-map"
            />
            <div
                style={{
                    position: 'absolute',
                    top: `${box.top}px`,
                    left: `${box.left}px`,
                    width: `${box.width}px`,
                    height: `${box.height}px`,
                    border: '3px solid #006aff',
                    boxSizing: 'border-box',
                    pointerEvents: 'none',
                    borderRadius: '5px',
                }}
            >
                {/*<div
                    onMouseDown={handleMouseDown('top-left')}
                    style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'blue',
                        position: 'absolute',
                        top: '-5px',
                        left: '-5px',
                        cursor: 'nwse-resize',
                        pointerEvents: 'auto',
                    }}
                />
                <div
                    onMouseDown={handleMouseDown('top-right')}
                    style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'blue',
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                        cursor: 'nesw-resize',
                        pointerEvents: 'auto',
                    }}
                />
                <div
                    onMouseDown={handleMouseDown('bottom-left')}
                    style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'blue',
                        position: 'absolute',
                        bottom: '-5px',
                        left: '-5px',
                        cursor: 'nesw-resize',
                        pointerEvents: 'auto',
                    }}
                />
                <div
                    onMouseDown={handleMouseDown('bottom-right')}
                    style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'blue',
                        position: 'absolute',
                        bottom: '-5px',
                        right: '-5px',
                        cursor: 'nwse-resize',
                        pointerEvents: 'auto',
                    }}
                />*/}
            </div>

        </div >
    );
}