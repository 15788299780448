import { add, andThen, groupBy, isEmpty, keys, map, pipe, view } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { axiosCurry, eventCheckedLens, getLastSundayTimestamp, HOUR_MS, responseDataLens, WEEK_MS } from '../utilities/utilities';
import { IconArrowBarToRight, IconArrowLeft, IconArrowRight, IconCaretDown, IconCaretRight, IconRestore } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/authSlice';
import { API_ROUTE } from '..';
import { getPrevMultiple } from '../edithistory/Utilities';

import './styles/TakeoffEditHistory.css';
import TakeoffPlan from './components/TakeoffPlan';
import { TakeoffContext } from './components/Context';
import RenderMeasurements from './components/RenderMeasurements';
import PageSidebar from './components/PageSidebar';
import RenderAnnotations from './components/RenderAnnotations';
import { Tooltip } from 'react-tooltip';
import { Blocks } from 'react-loader-spinner';
import Navbar from './components/Navbar';
import TakeoffLoading from '../takeoff/components/TakeoffLoading';
import Skeleton from 'react-loading-skeleton';

const START_TIME = getLastSundayTimestamp();

const TakeoffEditHistory = () => {
  const params = useParams();
  const projectUUID = params.projectUUID;
  const auth = useSelector(selectAuth);

  const takeoffEditHistoryAPI = useMemo(
    () => axiosCurry(`${API_ROUTE}/api/takeoff-edit-history-api/${projectUUID}/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }),
    [API_ROUTE, auth.token]
  );

  const [reverting, setReverting] = useState(false);

  const [loading, setLoading] = useState(true);

  const [project, setProject] = useState({ id: null });
  const [pages, setPages] = useState({});
  const [pageGroups, setPageGroups] = useState({});
  const [measurements, setMeasurements] = useState([]);
  const [loadedSnapshots, setLoadedSnapshots] = useState({});
  const [groupedSnapshots, setGroupedSnapshots] = useState({});
  const [settings, setSettings] = useState({
    id: null,
    angle_snap: true,
    angle_snap_distance: 3.0,
    angle_snap_45: true,
    angle_snap_90: true,
    angle_snap_45_prev: true,
    angle_snap_90_prev: true,
    scroll_sensitivity: 0.05,
    contractor: null,
    cursor_guides: true,
  });
  const [takeoffSettings, setTakeoffSettings] = useState({
    id: null,
    sort: 'type-up',
    show_measurement_sidebar: true,
    show_pages_sidebar: true,
    show_ai_sidebar: false,
    measurement_sidebar_location: 'right',
    pages_sidebar_location: 'left',
    ai_sidebar_location: 'left',
    measurement_sidebar_width: null,
    pages_sidebar_width: null,
    ai_sidebar_width: null,
    contractor: null,
    project: null,
  });
  const [currentPage, setCurrentPage] = useState(null);
  const [annotations, setAnnotations] = useState({});
  const [groups, setGroups] = useState({});
  const [tree, setTree] = useState([]);
  const [isFilteringByPage, setIsFilteringByPage] = useState(false);
  const [currentlyExpandedHour, setCurrentlyExpandedHour] = useState(null);
  const [currentSnapshotID, setCurrentSnapshotID] = useState(null);
  const [startTime, setStartTime] = useState(START_TIME);
  const endTime = startTime + WEEK_MS - 1;

  const handleNewSnapshots = (loadedSnapshots) => {
    const getHourOfSnapshot = (id) => getPrevMultiple(HOUR_MS)(loadedSnapshots[id].date);
    const groupedSnapshots = groupBy(getHourOfSnapshot)(keys(loadedSnapshots));
    setLoadedSnapshots(loadedSnapshots);
    setGroupedSnapshots(groupedSnapshots);

    setLoading(false);
  };

  const handleDetailedSnapshot = (detailedSnapshot) => {
    console.log(detailedSnapshot);
    setAnnotations(detailedSnapshot.annotations);
    setGroups(detailedSnapshot.groups);
    setMeasurements(detailedSnapshot.measurements);
    setPageGroups(detailedSnapshot.page_groups);
    setPages(detailedSnapshot.pages);
    setProject(detailedSnapshot.project);
    setTree(detailedSnapshot.tree);
    setCurrentPage(Object.keys(detailedSnapshot.pages)[0]);
  };

  useEffect(() => {
    pipe(takeoffEditHistoryAPI('post'), andThen(view(responseDataLens)), andThen(handleNewSnapshots))({ type: 'get_edit_history_date_range', start: startTime, end: endTime });
  }, [startTime]);

  if (loading) {
    return (
      <>
        <div className="takeoff-loading-navbar">
          <div>
            <a href='/dashboard'>
              <img
                src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png'
                alt='logo'
                className='takeoffnavbar-logo-loading'
              />
            </a>
          </div>
          <div><Skeleton width={200} /></div>
          <div><Skeleton width={350} /></div>
          <div><Skeleton width={100} /></div>
          <div><Skeleton width={200} /></div>
        </div>

        <div className="takeoff-loading">
          <div className="takeoff-loading-canvas">
            <Blocks
              visible={true}
              height="60"
              width="60"
              color="#006AFE"
              ariaLabel="blocks-loading"
              radius="10"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
            />
          </div>
          <div className="takeoff-loading-toolbar">
            <Skeleton circle={true} width={20} />
            <Skeleton circle={true} width={20} />
            <Skeleton circle={true} width={20} />
            <Skeleton circle={true} width={20} />
            <Skeleton circle={true} width={20} />
            <Skeleton circle={true} width={20} />
          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      <TakeoffContext.Provider value={{
        projectUUID,
        currentPage, setCurrentPage,
        project, setProject,
        pages, setPages,
        pageGroups, setPageGroups,
        measurements, setMeasurements,
        loadedSnapshots, groupedSnapshots, settings, takeoffSettings, annotations, groups, tree
      }}>
        <Navbar />

        {currentPage &&
          <PageSidebar />
        }

        {currentPage && pages[currentPage]?.file &&
          <TakeoffPlan
            url={pages[currentPage].file}
          >
            <RenderMeasurements />

            <RenderAnnotations />
          </TakeoffPlan>
        }

        {!currentPage &&
          <div className='takeoffedithistory-no-snapshot-selected'>
            Select a snapshot to view
          </div>
        }

        <div className='takeoffedithistory-snapshot-list'>
          <div>
            {(() => {
              const thisWeekTimestamp = getLastSundayTimestamp();
              const isThisWeek = startTime === thisWeekTimestamp;
              return (
                <div className='flex flex-row justify-between px-2'>
                  <button onClick={() => setStartTime(add(-WEEK_MS))} className='text-blue-bobyard'>
                    <IconArrowLeft />
                  </button>
                  <div className='flex flex-col items-center justify-center h-16 text-sm'>
                    <span>
                      {new Date(startTime).toLocaleDateString()} - {new Date(endTime).toLocaleDateString()}
                    </span>
                    <span>{isThisWeek && '(this week)'}</span>
                  </div>
                  <button onClick={() => setStartTime(add(WEEK_MS))} disabled={isThisWeek} className={`${isThisWeek ? '' : 'text-blue-bobyard'}`}>
                    <IconArrowRight />
                  </button>
                  <button onClick={() => setStartTime(thisWeekTimestamp)} disabled={isThisWeek} className={`${isThisWeek ? '' : 'text-blue-bobyard'}`}>
                    <IconArrowBarToRight />
                  </button>
                </div>
              );
            })()}
          </div>

          {isEmpty(loadedSnapshots) && <div className='takeoffedithistory-no-snapshots'>No snapshots to show for this week</div>}

          <div className='takeoffedithistory-snapshot-list-body'>
            {map((hourTimestampStr) => {
              const hourTimestamp = parseInt(hourTimestampStr);
              const date = new Date(hourTimestamp);
              const snapshots = groupedSnapshots[hourTimestampStr];
              const isExpanded = currentlyExpandedHour === hourTimestampStr;
              return (
                <div key={hourTimestampStr}>
                  <div
                    className='takeoffedithistory-snapshot-list-folder'
                    onClick={() => (currentlyExpandedHour == hourTimestampStr ? setCurrentlyExpandedHour(null) : setCurrentlyExpandedHour(hourTimestampStr))}
                  >
                    {!isExpanded && <IconCaretRight stroke={1} size={20} />}
                    {isExpanded && <IconCaretDown stroke={1} size={20} />}
                    <span>
                      {date.getMonth() + 1}/{date.getDate()}, {date.toLocaleTimeString().split(':')[0]} {date.toLocaleTimeString().split(' ')[1]}
                    </span>
                    <div className='takeoffedithistory-snapshot-list-folder-edit-count'>{snapshots.length} edits</div>
                  </div>
                  <div className='flex flex-col'>
                    {isExpanded &&
                      map((snapshotID) => {
                        const snapshotTime = new Date(loadedSnapshots[snapshotID].date).toLocaleTimeString();
                        return (
                          <div
                            key={snapshotID}
                            className={`takeoffedithistory-snapshot-list-item ${currentSnapshotID == snapshotID ? 'takeoffedithistory-snapshot-list-item-active' : ''}`}
                            onClick={() => {
                              if (currentSnapshotID !== snapshotID) {
                                setCurrentSnapshotID(snapshotID);
                                pipe(takeoffEditHistoryAPI('post'), andThen(view(responseDataLens)), andThen(handleDetailedSnapshot))({ type: 'get_detailed_snapshot', id: snapshotID, start: startTime, end: endTime });
                              }
                            }}
                          >
                            <div className='flex flex-row items-center justify-between'>
                              <div>
                                <div className='min-w-0 grow'>{snapshotTime}</div>
                                <div className='text-xs'>
                                  by {loadedSnapshots[snapshotID].contractor.first_name} {loadedSnapshots[snapshotID].contractor.last_name}
                                </div>
                              </div>
                              <div className='flex flex-row gap-2'>
                                <button
                                  id={'takeoffedithistory-snapshot-list-item-button' + snapshotID}
                                  className='takeoffedithistory-snapshot-list-item-button'
                                  onClick={(e) => {
                                    if (reverting) return;

                                    e.stopPropagation();
                                    setReverting(true);
                                    pipe(takeoffEditHistoryAPI('post'), andThen(view(responseDataLens)), andThen(handleNewSnapshots), andThen(() => setReverting(false)))({ type: 'restore_snapshot', id: snapshotID, start: startTime, end: endTime });
                                  }} //TODO
                                >
                                  {reverting
                                    ? <Blocks
                                      visible={true}
                                      height="20"
                                      width="20"
                                      color="#006AFE"
                                      ariaLabel="blocks-loading"
                                      radius="10"
                                      wrapperStyle={{}}
                                      wrapperClass="blocks-wrapper"
                                    />
                                    : <IconRestore stroke={1} size={20} />
                                  }
                                </button>

                                <Tooltip anchorSelect={`#takeoffedithistory-snapshot-list-item-button${snapshotID}`} place="bottom" delayShow={500} positionStrategy="fixed" style={{ zIndex: 1000 }}>
                                  Restore snapshot
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        );
                      })(snapshots)}
                  </div>
                </div>
              );
            })(keys(groupedSnapshots))}
          </div>
        </div>
      </TakeoffContext.Provider >
    </div >
  );
};

export default TakeoffEditHistory;
