import { Arrow, Circle, Group, Line, Rect, Text, Transformer } from "react-konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "../../helper/Context";
import { Html, Portal } from "react-konva-utils";
import ContextMenu from "../../../components/ContextMenu";
import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function AnnotationRuler({ annotation }) {
    const {
        annotations, setAnnotations,
        handleCreateAnnotation,
        handleUpdateAnnotation,
        pages, pageID,
        setEditingMeasurement,
        drawing,
        currentMeasurement, setCurrentMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        currentAnnotation, setCurrentAnnotation,
        selectedAnnotations, setSelectedAnnotations,
        setShowDeleteAnnotationModal,
        handleZoomToAnnotation,
    } = useContext(TakeoffContext);

    const isSelected = (currentAnnotation === annotation.id && !drawing && !currentMeasurement);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setCurrentAnnotation(null);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    let slope = (annotation.ruler.y2 - annotation.ruler.y1) / (annotation.ruler.x2 - annotation.ruler.x1);

    let distance_x = 10 / pages[pageID].zoom / Math.sqrt(1 + 1 / (slope * slope));
    let distance_y = - distance_x / slope;

    if (slope === Infinity || slope === -Infinity || slope === 0) {
        distance_x = 0;
        distance_y = 10 / pages[pageID].zoom;
    }

    let endTic = {
        x1: annotation.ruler.x2 + distance_x,
        y1: annotation.ruler.y2 + distance_y,
        x2: annotation.ruler.x2 - distance_x,
        y2: annotation.ruler.y2 - distance_y,
    }

    let startTic = {
        x1: annotation.ruler.x1 + distance_x,
        y1: annotation.ruler.y1 + distance_y,
        x2: annotation.ruler.x1 - distance_x,
        y2: annotation.ruler.y1 - distance_y,
    }

    let length = Math.sqrt(Math.pow(annotation.ruler.x2 - annotation.ruler.x1, 2) + Math.pow(annotation.ruler.y2 - annotation.ruler.y1, 2));

    if (annotation?.ruler?.uom === 'ft') {
        length = length * 1;
    } else if (annotation?.ruler?.uom === 'in') {
        length = length * 12;
    } else if (annotation?.ruler?.uom === 'yrd') {
        length = length / 3.0;
    } else if (annotation?.ruler?.uom === 'cm') {
        length = length * 30.48;
    } else if (annotation?.ruler?.uom === 'm') {
        length = length * 0.3048;
    }

    return (
        <>
            <Html>
                {showContextMenu && currentAnnotation === annotation.id &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        <div
                            className="contextmenu-item"
                            onClick={() => handleCreateAnnotation({
                                type: "ruler",
                                data: {
                                    x1: annotation.ruler.x1 + 10,
                                    y1: annotation.ruler.y1 + 10,
                                    x2: annotation.ruler.x2 + 10,
                                    y2: annotation.ruler.y2 + 10,
                                    size: annotation.size,
                                    stroke_color: annotation.stroke_color,
                                    fill_color: annotation.fill_color,
                                }
                            })}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>
                                Duplicate
                            </div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => handleZoomToAnnotation(annotation.id)}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>
                                Zoom
                            </div>
                        </div>

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => setShowDeleteAnnotationModal(true)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>
                                Delete
                            </div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                {pages[pageID].scale
                    ? <Text
                        x={(annotation.ruler.x1 + annotation.ruler.x2) / 2 + 1.5 * distance_x}
                        y={(annotation.ruler.y1 + annotation.ruler.y2) / 2 + 1.5 * distance_y}
                        text={(length && pages[pageID].scale) ? `${(length * pages[pageID].scale).toFixed(2)} ${annotation?.ruler?.uom || 'ft'}` : `No Scale`}
                        fontSize={12 / pages[pageID].zoom}
                        fill={annotation.stroke_color}
                        opacity={isSelected ? 0.7 : 0.5}
                    />
                    : <Text
                        x={(annotation.ruler.x1 + annotation.ruler.x2) / 2 + 1.5 * distance_x}
                        y={(annotation.ruler.y1 + annotation.ruler.y2) / 2 + 1.5 * distance_y}
                        text={`No Scale`}
                        fontSize={12 / pages[pageID].zoom}
                        fill={'#006aff'}
                        opacity={0.7}
                    />
                }

                <Line
                    points={[annotation.ruler.x1, annotation.ruler.y1, annotation.ruler.x2, annotation.ruler.y2]}
                    fill={annotation.fill_color}
                    stroke={annotation.stroke_color}
                    strokeWidth={2}
                    dash={[annotation.size, annotation.size / 2]}
                    opacity={isSelected || selectedAnnotations.includes(annotation.id) ? 0.7 : 0.5}

                    draggable={isSelected && !drawing}
                    onDragMove={(e) => {
                    }}
                    onDragEnd={(e) => {
                        handleUpdateAnnotation(annotations[annotation.id])
                    }}
                    strokeScaleEnabled={false}
                    perfectDrawEnabled={false}
                />

                <Circle
                    x={annotation.ruler.x1}
                    y={annotation.ruler.y1}
                    radius={((annotation.ruler.x1 - annotation.ruler.x2) ** 2 + (annotation.ruler.y1 - annotation.ruler.y2) ** 2) ** 0.5}
                    opacity={isSelected || selectedAnnotations.includes(annotation.id) ? 0.5 : 0.3}
                    stroke={annotation.stroke_color}
                    strokeWidth={1 / pages[pageID].zoom}
                    dash={[annotation.size, annotation.size / 4]}
                />

                {startTic.x1 &&
                    <>
                        <Line
                            points={[endTic.x1, endTic.y1, endTic.x2, endTic.y2]}
                            stroke={annotation.stroke_color}
                            strokeWidth={2 / pages[pageID].zoom}
                        />

                        <Line
                            points={[startTic.x1, startTic.y1, startTic.x2, startTic.y2]}
                            stroke={annotation.stroke_color}
                            strokeWidth={2 / pages[pageID].zoom}
                        />
                    </>
                }


                <Line
                    points={[annotation.ruler.x1, annotation.ruler.y1, annotation.ruler.x2, annotation.ruler.y2]}
                    strokeWidth={10 / pages[pageID].zoom}
                    stroke={annotation.stroke_color}
                    opacity={isSelected ? 0.1 : 0}
                    onClick={(e) => {
                        if (e.evt.button !== 0) return;

                        if (e.evt.metaKey) {
                            if (selectedAnnotations.includes(annotation.id)) {
                                setSelectedAnnotations(selectedAnnotations.filter((id) => id !== annotation.id));
                            } else {
                                setSelectedAnnotations([...selectedAnnotations, annotation.id]);
                            }
                        } else {
                            setSelectedAnnotations([annotation.id]);
                            setCurrentAnnotation(annotation.id)
                        }

                        setCurrentMeasurement(null);
                        setSelectedMeasurements([]);
                    }}
                    onContextMenu={(e) => {
                        e.evt.preventDefault();
                        setSelectedAnnotations([annotation.id]);
                        setCurrentAnnotation(annotation.id);
                        setCurrentMeasurement(null);

                        setContextMenuPosition({
                            x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                            y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                        })
                        setShowContextMenu(true);
                    }}
                />


                {isSelected &&
                    <Circle
                        x={annotation.ruler.x1}
                        y={annotation.ruler.y1}
                        radius={5 / pages[pageID].zoom}
                        fill={annotation.fill_color}
                        stroke={annotation.stroke_color}
                        strokeWidth={1}
                        opacity={isSelected ? 0.7 : 0.5}
                        draggable={isSelected && !drawing}
                        onDragMove={(e) => {
                            setAnnotations({
                                ...annotations,
                                [annotation.id]: {
                                    ...annotation,
                                    ruler: {
                                        ...annotation.ruler,
                                        x1: e.target.x(),
                                        y1: e.target.y(),
                                    }
                                }
                            })
                        }}
                        onDragEnd={(e) => {
                            handleUpdateAnnotation(annotations[annotation.id])
                        }}
                    />
                }

                {isSelected &&
                    <Circle
                        x={annotation.ruler.x2}
                        y={annotation.ruler.y2}
                        radius={5 / pages[pageID].zoom}
                        fill={annotation.fill_color}
                        stroke={annotation.stroke_color}
                        strokeWidth={1}
                        opacity={isSelected ? 0.7 : 0.5}
                        draggable={isSelected && !drawing}
                        onDragMove={(e) => {
                            setAnnotations({
                                ...annotations,
                                [annotation.id]: {
                                    ...annotation,
                                    ruler: {
                                        ...annotation.ruler,
                                        x2: e.target.x(),
                                        y2: e.target.y(),
                                    }
                                }
                            })
                        }}
                        onDragEnd={(e) => {
                            handleUpdateAnnotation(annotations[annotation.id])
                        }}
                    />
                }
            </Portal>
        </>
    )
}