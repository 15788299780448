import { useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';

import { API_ROUTE } from '../../..';
import { selectAuth } from '../../../redux/slices/authSlice';
import { TakeoffContext } from '../../helper/Context';

import '../../styles/AIPopup.css';

import DefaultButton from '../../../components/DefaultButton';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { IconCircleCheckFilled, IconProgressCheck, IconRobot } from '@tabler/icons-react';
import { Modal } from 'react-bootstrap';
import Popup from 'reactjs-popup';

export default function AISidebarLength({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        setMeasurements, setGroups,
        setTree,
        setPageGroups, setUngroupedPages,
        hintingSAM, setHintingSAM,
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        AIAutoFinding, setAIAutoFinding,
        currentMeasurement, setCurrentMeasurement,
        hintingAutoFindDW, setHintingAutoFindDW,
        hintingAutoMeasure, setHintingAutoMeasure,
        hintingAutoMeasurePerim, setHintingAutoMeasurePerim,
        AIMessages, setAIMessages,
        currentGroup,
        createMeasurements,
        createMeasurement,
        setAIAutoCountRect,
        setAIAutoCountPoly,
        autoNameAllPages, setAutoNameAllPages,
        showLegendsPopup, setShowLegendsPopup,
        AILegends,
        autoCountReference, setAutoCountReference,
        autoCountLocation, setAutoCountLocation,
        AIAutoCountExample, setAIAutoCountExample,
        handleTurnOffDrawing,
        AIAutoMeasureLengthRect, setAIAutoMeasureLengthRect,
        hintingLength, setHintingLength,
        AIClassAgLength, setAIClassAgLength,
        LengthAssignment, setLengthAssignment,
    } = useContext(TakeoffContext);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === ' ' && !document.activeElement.tagName.match('TEXTAREA|INPUT') && pages?.[pageID]?.ai_prepped_length) {
                handleTurnOffDrawing();
                setLengthAssignment(true);
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [pages[pageID]?.ai_prepped_length]);

    const handleAutoMeasureRoomPerim = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-measure-room-perim/`,
            data: {
                'page': pageID,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePrepLength = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/ai-get-pipes/`,
            data: {
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleCreateLengths = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/create-pipe-lengths/`,
            data: {
                'page': pageID,
                'location': 'all',
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleHintLength = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/sam-roads-inference/`,
            data: {
                page: pageID,
                x: 200 / pages[pageID].zoom,
                y: 200 / pages[pageID].zoom,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api/count/`,
                    data: {
                        'userID': auth.user.id,
                        'pageID': pageID,
                        'group': currentGroup,
                        'dots': [...response.data.map((dot) => ({
                            x: dot[1],
                            y: dot[0],
                        }))],
                        'time': 0.01,
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json"
                    },
                })
                    .then((response) => {
                        console.log(response);

                        createMeasurement(response.data, true);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePrepLengths = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/class-ag-length-sam-inference/`,
            data: {
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                /*setAIClassAgLength(response.data);
                setPages(prev => ({
                    ...prev,
                    [pageID]: {
                        ...prev[pageID],
                        'ai_prepped_length': true,
                    }
                }));*/
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <div className='aisidebar-length-container'>
            <>
                {/*<div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Room perimeters
                    </div>
                </div>
                <div className='navbar-aipopup-group-body'>
                    {AIMessages?.[pageID]?.autoSegRoomsPerim?.running
                        ? <div className="navbar-aipopup-group-item">
                            Automatic
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : <>
                            <div
                                id='navbar-aipopup-item-auto-measure-room-perim'
                                className="navbar-aipopup-group-item"
                                onClick={() => {
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            ...prev[pageID],
                                            'autoSegRoomsPerim': {
                                                'progress': 1 / 100,
                                                'show': true,
                                                'running': true,
                                            }
                                        }
                                    }));
                                    handleAutoMeasureRoomPerim();
                                }}
                            >
                                Automatic
                            </div>

                            <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-auto-measure-room-perim'>
                                Automatically measure perimeter of all rooms
                            </Tooltip>
                        </>
                    }

                    {AIMessages?.[pageID]?.autoSegRoomsPerimCrop?.running
                        ? <div className="navbar-aipopup-group-item">
                            Crop
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : hintingAutoMeasurePerim?.[pageID]?.show
                            ? <div
                                id='navbar-aipopup-item-auto-measure-room-perim-hints-active'
                                className="navbar-aipopup-group-item-active"
                                onClick={() => {
                                    handleTurnOffDrawing();
                                }}
                            >
                                Crop
                            </div>
                            : <div
                                id='navbar-aipopup-item-auto-measure-room-perim-hints'
                                className="navbar-aipopup-group-item"
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    setHintingAutoMeasurePerim(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            ...prev[pageID],
                                            show: true,
                                        }
                                    }))
                                }}
                            >
                                Crop
                            </div>
                    }

                    <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-auto-measure-room-perim-hints-active'>
                        <div>Draw a rectangular crop to auto measure perimeters for</div>
                        <div>Click to escape</div>
                    </Tooltip>

                    <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-auto-measure-room-perim-hints'>
                        Draw a rectangular crop to auto measure perimeters for
                    </Tooltip>
                </div>
            </div>

            <hr className='navbar-aipopup-hr'></hr>*/}

                {/*
            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Run lengths
                    </div>
                </div>
                <div className='navbar-aipopup-group-body'>
                    {hintingLength
                        ? <div
                            id='navbar-aipopup-item-hint-sam-active'
                            className='navbar-aipopup-group-item-active'
                            onClick={() => {
                                handleTurnOffDrawing();
                            }}
                        >
                            Hint length
                        </div>
                        : <div
                            id='navbar-aipopup-item-hint-sam'
                            className="navbar-aipopup-group-item"
                            onClick={() => {
                                handleTurnOffDrawing();

                                setHintingLength(true);
                                setCurrentMeasurement(null);
                            }}
                        >
                            Hint length
                        </div>
                    }
                </div>
            </div>*/}
            </>

            <div className='aisidebar-count-container'>
                <div
                    className='aisidebar-count-group'
                    style={{
                        backgroundColor: !pages[pageID]?.ai_prepped_length ? 'whitesmoke' : 'white',
                    }}
                >
                    <div
                        className='aisidebar-count-group-progress-first'
                        style={{
                            borderColor: pages[pageID]?.ai_prepped_length ? '#006AFF' : 'silver',
                        }}
                    >
                        <div
                            className='aisidebar-count-group-progress-dot-first'
                            style={{
                                backgroundColor: pages[pageID]?.ai_prepped_length ? '#006AFF' : 'white',
                                borderColor: '#006AFF',
                                boxShadow: !pages[pageID]?.ai_prepped_length && '0px 0px 4px 1px #006AFF',
                            }}
                        >
                            &nbsp;
                        </div>
                    </div>
                    <div className='aisidebar-count-group-content'>
                        <div className='aisidebar-count-group-header'>
                            <div
                                className='aisidebar-count-group-title'
                                style={{
                                    color: !pages[pageID]?.ai_prepped_length && '#006aff',
                                }}
                            >
                                Prep page for lengths
                            </div>
                            <Popup
                                trigger={open => (
                                    <div className='aisidebar-count-group-header-help'>
                                        ?
                                    </div>
                                )}
                                on={['click', 'hover']}
                                position="bottom center"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                            >
                                <div className='aisidebar-count-group-help'>
                                    Prep the page for the AI to measure lengths
                                </div>
                            </Popup>
                        </div>
                        <div className='aisidebar-count-group-body'>
                            <div className='aisidebar-count-group-2choices'>
                                <div
                                    className={'aisidebar-count-group-choice aisidebar-count-group-2choices-right '
                                        + (pages[pageID]?.ai_prepped_length && ' aisidebar-count-group-choice-active')}
                                    onClick={() => {
                                        if (!pages[pageID]?.ai_prepped_length) {
                                            handlePrepLengths();
                                        }
                                    }}
                                    style={{
                                        backgroundColor: !pages[pageID]?.ai_prepped_length && 'white',
                                        borderColor: !pages[pageID]?.ai_prepped_length && '#006aff',
                                        borderRadius: '3px',
                                    }}
                                >
                                    {pages[pageID]?.ai_prepped_length
                                        ? 'Prepped'
                                        : AIMessages?.[pageID]?.lengthPrep?.running
                                            ? 'Prepping'
                                            : 'Start Prep'
                                    }
                                    {AIMessages?.[pageID]?.lengthPrep?.running &&
                                        <>
                                            &nbsp;
                                            <Blocks
                                                visible={true}
                                                height="20"
                                                width="20"
                                                color="#006AFE"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                            />
                                        </>
                                    }
                                    {pages[pageID]?.ai_prepped_length &&
                                        <>
                                            &nbsp;
                                            <IconCircleCheckFilled size={20} style={{ color: '#006afe' }} />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className='aisidebar-count-group'
                    style={{
                        backgroundColor: pages[pageID].ai_prepped_length
                            ? 'whitesmoke'
                            : 'white'
                    }}
                >
                    <div
                        className='aisidebar-count-group-progress-last'
                        style={{
                            borderColor: pages[pageID].ai_prepped_length ? '#006AFF' : 'silver',
                        }}
                    >
                        <div
                            className='aisidebar-count-group-progress-dot'
                            style={{
                                backgroundColor: pages[pageID].ai_prepped_length && LengthAssignment
                                    ? '#006AFF'
                                    : 'white',
                                borderColor: pages[pageID].ai_prepped_length ? '#006AFF' : 'silver',
                                boxShadow: pages[pageID].ai_prepped_length && '0px 0px 4px 1px #006AFF',
                            }}
                        >
                            &nbsp;
                        </div>
                    </div>
                    <div className='aisidebar-count-group-content'>
                        <div className='aisidebar-count-group-header'>
                            <div
                                className='aisidebar-count-group-title'
                                style={{
                                    color: pages[pageID].ai_prepped_length ? '#006aff' : 'silver',
                                }}
                            >
                                Start assignment
                            </div>
                            <Popup
                                trigger={open => (
                                    <div className='aisidebar-count-group-header-help'>
                                        ?
                                    </div>
                                )}
                                on={['click', 'hover']}
                                position="bottom center"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                            >
                                <div className='aisidebar-count-group-help'>
                                    <div><b>Assign AI found lengths</b></div>

                                    <div>
                                        1. Click on a line to create a length measurement. Holding 'shift' and drawing a rectangle will create measurements for all selected lines.
                                    </div>

                                    <div>
                                        A length measurement will be created for each of the currently selected groups.
                                    </div>
                                </div>
                            </Popup>
                        </div>
                        <div className='aisidebar-count-group-body'>
                            <div
                                className={'aisidebar-count-auto-count-button '
                                    + (pages[pageID]?.ai_prepped_length && ' aisidebar-count-auto-count-button-active ')
                                    + (LengthAssignment && ' aisidebar-count-auto-count-button-running ')
                                    + (!pages[pageID]?.ai_prepped_length && ' aisidebar-count-auto-count-button-disabled ')
                                }
                                onClick={() => {
                                    if (pages[pageID]?.ai_prepped_length) {
                                        handleTurnOffDrawing();

                                        setLengthAssignment(prev => !prev);
                                    }
                                }}
                            >
                                {LengthAssignment ? 'Stop (ESC)' : 'Start (SPACE)'}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/*
            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Prep page
                    </div>
                </div>

                <div
                    id='navbar-aipopup-item-hint-sam'
                    className='navbar-aipopup-group-item'
                    onClick={() => {
                        handlePrepLengths();
                    }}
                >
                    Prep length
                </div>
            </div>
            
            {pages[pageID]?.ai_prepped_length &&
                <div>
                    prepped
                </div>
            }


            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Assignment mode
                    </div>
                </div>

                <div
                    id='navbar-aipopup-item-hint-sam'
                    className='navbar-aipopup-group-item'
                    onClick={() => {
                        setLengthAssignment(prev => !prev);
                    }}
                >
                    Start
                </div>
            </div>*/}

            {/*
            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Auto fill length
                    </div>
                </div>

                {hintingLength
                    ? <div
                        id='navbar-aipopup-item-hint-sam-active'
                        className='navbar-aipopup-group-item-active'
                        onClick={() => {
                            handleTurnOffDrawing();
                        }}
                    >
                        Hint auto fill
                    </div>
                    : <div
                        id='navbar-aipopup-item-hint-sam'
                        className='navbar-aipopup-group-item'
                        onClick={() => {
                            handleTurnOffDrawing();

                            setHintingLength(true);
                            setCurrentMeasurement(null);
                        }}
                    >
                        Hint auto fill
                    </div>}
            </div>*/}

            {/*pages[pageID]?.ai_prepped_length
                ? <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Run lengths
                        </div>
                    </div>
                    <div className='navbar-aipopup-group-body'>
                        <div
                            className="navbar-aipopup-group-item"
                            onClick={() => handleCreateLengths()}
                        >
                            Whole page
                        </div>

                        <div
                            className="navbar-aipopup-group-item"
                            onClick={() => setAIAutoMeasureLengthRect(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev[pageID],
                                    start: null,
                                    end: null,
                                    isComplete: false,
                                    show: true,
                                }
                            }))}
                        >
                            Rectangle
                        </div>
                    </div>
                </div>
                : <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Prep line lengths
                        </div>
                    </div>
                    <div className='navbar-aipopup-group-body'>
                        <div
                            className="navbar-aipopup-group-item"
                            onClick={() => handlePrepLength()}
                        >
                            Prep
                        </div>
                    </div>
                </div>
            */}
        </div >
    )
}