import { useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import axios from 'axios';
import Popup from 'reactjs-popup';
import TextareaAutosize from 'react-textarea-autosize';

import { v4 as uuidv4 } from 'uuid';

import { API_ROUTE } from '../../..';
import { selectAuth } from '../../../redux/slices/authSlice';
import { TakeoffContext } from '../../helper/Context';

import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu
} from "react-contexify";

import './styles/AISidebar.css';

import DefaultButton from '../../../components/DefaultButton';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { IconBaseline, IconCircleCheckFilled, IconLayoutGrid, IconLayoutNavbarCollapse, IconRobot, IconSettings, IconSquarePlus, IconTrashX } from '@tabler/icons-react';
import { Modal } from 'react-bootstrap';
import ActiveButton from '../../../components/ActiveButton';
import { IconTablePlus } from '@tabler/icons-react';
import { IconListDetails } from '@tabler/icons-react';
import { IconSquareRoundedPlus } from '@tabler/icons-react';
import { IconSwitch3 } from '@tabler/icons-react';

export default function AISidebarCount({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        project, setProject,
        pages, setPages,
        setMeasurements, setGroups,
        setTree,
        setPageGroups, setUngroupedPages,
        hintingSAM, setHintingSAM,
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        AIAutoFinding, setAIAutoFinding,
        currentMeasurement, setCurrentMeasurement,
        hintingAutoFindDW, setHintingAutoFindDW,
        hintingAutoMeasure, setHintingAutoMeasure,
        hintingAutoMeasurePerim, setHintingAutoMeasurePerim,
        AIMessages, setAIMessages,
        setAIAutoCountRect,
        setAIAutoCountPoly,
        autoNameAllPages, setAutoNameAllPages,
        showLegendsPopup, setShowLegendsPopup,
        autoCountReference, setAutoCountReference,
        autoCountLocation, setAutoCountLocation,
        AIAutoCountExample, setAIAutoCountExample,
        AILegends, setAILegends,
        selectingLegend, setSelectingLegend,
        currentLegend, setCurrentLegend,
        handleTurnOffDrawing,
        AIAutoCountRect,
        AIAutoCountPoly,
        AILegendViews, setAILegendViews,
    } = useContext(TakeoffContext);

    const [prepTrade, setPrepTrade] = useState(null);

    useEffect(() => {
        if (AILegends && Object.keys(AILegends).length !== Object.keys(AILegendViews).length) {
            setAILegendViews(Object.keys(AILegends).reduce((obj, key) => {
                obj[key] = AILegendViews[key] || 'hide';
                return obj;
            }, {}));
        }
    }, [AILegends, AILegendViews]);

    const handlePrep = (trade) => {
        setPrepTrade(trade);

        if (pages[pageID]?.ai_prepped) {
            setPages(prev => ({
                ...prev,
                [pageID]: {
                    ...prev[pageID],
                    trade: trade,
                }
            }));

            axios({
                method: 'put',
                url: `${API_ROUTE}/api/prep-page/`,
                data: {
                    'page': pageID,
                    'trade': trade,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setPages(prev => ({
                ...prev,
                [pageID]: {
                    ...prev[pageID],
                    trade: trade,
                }
            }));

            axios({
                method: 'post',
                url: `${API_ROUTE}/api/prep-page/`,
                data: {
                    'page': pageID,
                    'trade': trade,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const handleCreateLegend = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/aivirtuallegends/`,
            data: {
                name: 'New legend',
                project: project.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log(res);

                setAILegends(prev => ({
                    ...prev,
                    [res.data.id]: {
                        ...res.data,
                        entries: [],
                    }
                }))
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleAutoCount = () => {
        handleTurnOffDrawing();

        setAIMessages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                'autoCount': {
                    ...prev[pageID].autoCount,
                    'progress': 1 / 100,
                    'show': true,
                    'running': false,
                }
            }
        }));

        if (autoCountReference === 'auto') {
            if (autoCountLocation === 'auto') {
                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api/ai-auto-count/`,
                    data: {
                        'page': pageID,
                        'contractor': auth.contractor.id,
                        'reference': 'auto',
                        'location': 'auto',
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (autoCountLocation === 'rectangle') {
                setAIAutoCountRect(prev => ({
                    ...prev,
                    [pageID]: {
                        start: null,
                        end: null,
                        show: true,
                        isComplete: false,
                    }
                }));
            } else if (autoCountLocation === 'polygon') {
                setAIAutoCountPoly(prev => ({
                    ...prev,
                    [pageID]: {
                        ...prev?.[pageID],
                        points: [],
                        nextPoint: null,
                        isComplete: false,
                        show: true,
                    }
                }));
            }
        } else if (autoCountReference === 'example') {
            if (autoCountLocation === 'auto') {
                setAIAutoCountExample(prev => ({
                    ...prev,
                    [pageID]: {
                        symbol: null,
                        show: true,
                    }
                }));
            } else if (autoCountLocation === 'rectangle') {
                setAIAutoCountExample(prev => ({
                    ...prev,
                    [pageID]: {
                        symbol: null,
                        show: true,
                    }
                }));
            } else if (autoCountLocation === 'polygon') {
                setAIAutoCountExample(prev => ({
                    ...prev,
                    [pageID]: {
                        symbol: null,
                        show: true,
                    }
                }));
            }
        } else if (autoCountReference === 'legend') {
            if (autoCountLocation === 'auto') {
                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api/ai-auto-count/`,
                    data: {
                        'page': pageID,
                        'contractor': auth.contractor.id,
                        'reference': 'legend',
                        'location': 'auto',
                        'legend': currentLegend || Object.keys(AILegends)[0],
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (autoCountLocation === 'rectangle') {
                setAIAutoCountRect(prev => ({
                    ...prev,
                    [pageID]: {
                        start: null,
                        end: null,
                        show: true,
                    }
                }));
            } else if (autoCountLocation === 'polygon') {
                setAIAutoCountPoly(prev => ({
                    ...prev,
                    [pageID]: {
                        ...prev?.[pageID],
                        points: [],
                        nextPoint: null,
                        isComplete: false,
                        show: true,
                    }
                }));
            }
        }
    }

    const currentStep = pages[pageID]?.ai_prepped
        ? (autoCountReference === 'example' || (autoCountReference === 'legend' && currentLegend && AILegends?.[currentLegend]?.entries?.length))
            ? autoCountLocation
                ? AIMessages?.[pageID]?.autoCount?.running || AIAutoCountExample?.[pageID]?.show || AIAutoCountRect?.[pageID]?.show || AIAutoCountPoly?.[pageID]?.show
                    ? 5
                    : 4
                : 3
            : 2
        : 1;

    return (
        <div className='aisidebar-count-container'>
            <div
                className='aisidebar-count-group'
                style={{
                    backgroundColor: currentStep === 1 && 'whitesmoke',
                }}
            >
                <div
                    className='aisidebar-count-group-progress-first'
                    style={{
                        borderColor: currentStep > 1 ? '#006AFF' : 'silver',
                    }}
                >
                    <div
                        className='aisidebar-count-group-progress-dot-first'
                        style={{
                            backgroundColor: currentStep > 1 ? '#006AFF' : 'white',
                            borderColor: currentStep >= 1 ? '#006AFF' : 'silver',
                            boxShadow: currentStep == 1 && '0px 0px 4px 1px #006AFF',
                        }}
                    >
                        &nbsp;
                    </div>
                </div>
                <div className='aisidebar-count-group-content'>
                    <div className='aisidebar-count-group-header'>
                        <div
                            className='aisidebar-count-group-title'
                            style={{
                                color: currentStep === 1 && '#006aff',
                            }}
                        >
                            Prep page for counting
                        </div>
                        <Popup
                            trigger={open => (
                                <div className='aisidebar-count-group-header-help'>
                                    ?
                                </div>
                            )}
                            on={['click', 'hover']}
                            position="bottom center"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                        >
                            <div className='aisidebar-count-group-help'>
                                Prep the page for the AI to count
                            </div>
                        </Popup>
                    </div>
                    <div className='aisidebar-count-group-body'>
                        <div className='aisidebar-count-group-2choices'>
                            {/*<div
                                className={'aisidebar-count-group-choice aisidebar-count-group-2choices-left '
                                    + ((pages[pageID]?.ai_prepped && pages[pageID]?.trade == 'electrical') && ' aisidebar-count-group-choice-active')}
                                onClick={() => {
                                    if (pages[pageID]?.trade !== 'electrical' && !AIMessages?.[pageID]?.prepLocalization?.running) {
                                        handlePrep('electrical');
                                    }
                                }}
                                style={{
                                    backgroundColor: currentStep === 1 && 'white',
                                    borderColor: currentStep === 1 && '#006aff',
                                }}
                            >
                                Electrical
                            </div>*/}
                            <div
                                className={'aisidebar-count-group-choice aisidebar-count-group-2choices-right '
                                    + ((pages[pageID]?.ai_prepped && pages[pageID]?.trade == 'landscaping') && ' aisidebar-count-group-choice-active')}
                                onClick={() => {
                                    if (pages[pageID]?.trade !== 'landscaping' && !AIMessages?.[pageID]?.prepLocalization?.running) {
                                        handlePrep('landscaping');
                                    }
                                }}
                                style={{
                                    backgroundColor: currentStep === 1 && 'white',
                                    borderColor: currentStep === 1 && '#006aff',
                                    borderRadius: '3px',
                                }}
                            >
                                {pages[pageID]?.ai_prepped && pages[pageID]?.trade == 'landscaping'
                                    ? <>Prepped</>
                                    : AIMessages?.[pageID]?.prepLocalization?.running
                                        ? <>Prepping</>
                                        : <>Start Prep</>
                                }

                                {AIMessages?.[pageID]?.prepLocalization?.running &&
                                    <>
                                        &nbsp;
                                        <Blocks
                                            visible={true}
                                            height="20"
                                            width="20"
                                            color="#006AFE"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                        />
                                    </>
                                }
                                {pages[pageID]?.ai_prepped &&
                                    <>
                                        &nbsp;
                                        <IconCircleCheckFilled size={20} style={{ color: '#006afe' }} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='aisidebar-count-group'
                style={{
                    backgroundColor: currentStep === 2
                        ? 'whitesmoke'
                        : AIAutoCountExample?.[pageID]?.show && !AIAutoCountPoly?.[pageID]?.show && !AIAutoCountRect?.[pageID]?.show && !AIMessages?.[pageID]?.autoCount?.running
                            ? 'aliceblue'
                            : '',
                }}
            >
                <div className='aisidebar-count-group-progress'>
                    <div
                        className='aisidebar-count-group-progress-top'
                        style={{
                            borderColor: currentStep > 1 ? '#006AFF' : 'silver',
                        }}
                    >
                    </div>
                    <div
                        className='aisidebar-count-group-progress-bottom'
                        style={{
                            borderColor: currentStep > 2 ? '#006AFF' : 'silver',
                        }}>
                    </div>
                    <div
                        className='aisidebar-count-group-progress-dot'
                        style={{
                            backgroundColor:
                                AIAutoCountExample?.[pageID]?.show && !AIAutoCountPoly?.[pageID]?.show && !AIAutoCountRect?.[pageID]?.show && !AIMessages?.[pageID]?.autoCount?.running
                                    ? 'white'
                                    : currentStep > 2 ? '#006AFF' : 'white',
                            borderColor: currentStep >= 2 ? '#006AFF' : 'silver',
                            boxShadow: (currentStep === 2 || AIAutoCountExample?.[pageID]?.show && !AIAutoCountPoly?.[pageID]?.show && !AIAutoCountRect?.[pageID]?.show && !AIMessages?.[pageID]?.autoCount?.running) && '0px 0px 4px 1px #006AFF',
                        }}
                    >
                        &nbsp;
                    </div>
                </div>
                <div className='aisidebar-count-group-content'>
                    <div className='aisidebar-count-group-header'>
                        <div
                            className='aisidebar-count-group-title'
                            style={{
                                color: currentStep < 2
                                    ? 'silver'
                                    : currentStep === 2
                                        ? '#006aff'
                                        : '',
                            }}
                        >
                            Choose a reference
                        </div>
                        <Popup
                            trigger={open => (
                                <div className='aisidebar-count-group-header-help'>
                                    ?
                                </div>
                            )}
                            on={['click', 'hover']}
                            position="bottom center"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                        >
                            <div className='aisidebar-count-group-help'>
                                <div>
                                    Choose a reference for the AI to count
                                </div>
                                <div>
                                    <b>Legend:</b> Count based on a legend you select
                                </div>
                                <div>
                                    <b>Example:</b> Count based on an example you select
                                </div>
                            </div>
                        </Popup>
                    </div>
                    <div className='aisidebar-count-group-body'>
                        <div className='aisidebar-count-group-2choices'>
                            <div
                                className={'aisidebar-count-group-choice aisidebar-count-group-2choices-left '
                                    + (autoCountReference === 'legend' && pages[pageID]?.ai_prepped && ' aisidebar-count-group-choice-active ')
                                    + ((currentStep < 2 || !pages[pageID]?.ai_prepped) && ' aisidebar-count-group-choice-disabled ')
                                }
                                onClick={() => {
                                    if (!AIMessages?.[pageID]?.autoCount?.running && currentStep >= 2) {
                                        handleTurnOffDrawing();
                                        setAutoCountReference('legend');
                                    }
                                }}
                                style={{
                                    backgroundColor: currentStep === 2 && pages[pageID]?.ai_prepped && 'white',
                                    borderColor: currentStep === 2 && pages[pageID]?.ai_prepped && '#006aff',
                                }}
                            >
                                Legend
                            </div>
                            <div
                                className={'aisidebar-count-group-choice aisidebar-count-group-2choices-right '
                                    + (autoCountReference === 'example' && pages[pageID]?.ai_prepped && ' aisidebar-count-group-choice-active ')
                                    + ((currentStep < 2 || !pages[pageID]?.ai_prepped) && ' aisidebar-count-group-choice-disabled ')
                                }
                                onClick={() => {
                                    if (!AIMessages?.[pageID]?.autoCount?.running && currentStep >= 2) {
                                        handleTurnOffDrawing();
                                        setAutoCountReference('example');
                                    }
                                }}
                                style={{
                                    backgroundColor: currentStep === 2 && pages[pageID]?.ai_prepped && 'white',
                                    borderColor: currentStep === 2 && pages[pageID]?.ai_prepped && '#006aff',
                                }}
                            >
                                Example
                            </div>
                        </div>

                        {autoCountReference === 'legend' && pages[pageID]?.ai_prepped &&
                            <div className='aisidebar-count-group-legend'>
                                <div className='aisidebar-count-legends-header'>
                                    <div>
                                        Legends
                                    </div>

                                    <div className='aisidebar-count-legends-header-buttons'>
                                        <div
                                            className='aisidebar-count-legend-create-button'
                                            id={'aisidebar-count-legend-collapse-button'}
                                            onClick={() => {
                                                setAILegendViews(prev => {
                                                    return Object.keys(prev).reduce((obj, key) => {
                                                        obj[key] = 'hide';
                                                        return obj;
                                                    }, {});
                                                });
                                            }}
                                        >
                                            <IconLayoutNavbarCollapse size={20} stroke={1} />
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect='#aisidebar-count-legend-collapse-button'>
                                            Collapse all
                                        </Tooltip>

                                        <div
                                            className='aisidebar-count-legend-create-button'
                                            id={'aisidebar-count-legend-create-button'}
                                            onClick={() => handleCreateLegend()}
                                        >
                                            <IconSquareRoundedPlus size={20} stroke={1} />
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect='#aisidebar-count-legend-create-button'>
                                            Create legend
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='ailegend-popup-container'>
                                    {AILegends && Object.keys(AILegends).length
                                        ? <div className='aisidebar-count-legends'>
                                            {Object.values(AILegends).sort((a, b) => b.id - a.id).map((legend, index) =>
                                                <Legend
                                                    key={index}
                                                    legend={legend}
                                                    AILegendViews={AILegendViews}
                                                    setAILegendViews={setAILegendViews}
                                                />
                                            )}
                                        </div>
                                        : <div
                                            className='aisidebar-count-group-no-legends'
                                            onClick={() => handleCreateLegend()}
                                        >
                                            <IconSquareRoundedPlus size={20} stroke={1} />
                                            <div>Create legend</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div
                className='aisidebar-count-group'
                style={{
                    backgroundColor: currentStep === 3
                        ? 'whitesmoke'
                        : (AIAutoCountRect?.[pageID]?.show || AIAutoCountPoly?.[pageID]?.show) && !AIMessages?.[pageID]?.autoCount?.running
                            ? 'aliceblue'
                            : '',
                }}
            >
                <div className='aisidebar-count-group-progress'>
                    <div
                        className='aisidebar-count-group-progress-top'
                        style={{
                            borderColor: currentStep > 2 ? '#006AFF' : 'silver',
                        }}
                    >
                    </div>
                    <div
                        className='aisidebar-count-group-progress-bottom'
                        style={{
                            borderColor: currentStep > 3 ? '#006AFF' : 'silver',
                        }}>
                    </div>
                    <div
                        className='aisidebar-count-group-progress-dot'
                        style={{
                            backgroundColor: (AIAutoCountRect?.[pageID]?.show || AIAutoCountPoly?.[pageID]?.show) && !AIMessages?.[pageID]?.autoCount?.running
                                ? 'white'
                                : currentStep > 3 ? '#006AFF' : 'white',
                            borderColor: currentStep >= 3 ? '#006AFF' : 'silver',
                            boxShadow: (currentStep == 3 || ((AIAutoCountRect?.[pageID]?.show || AIAutoCountPoly?.[pageID]?.show) && !AIMessages?.[pageID]?.autoCount?.running)) && '0px 0px 4px 1px #006AFF',
                        }}
                    >
                        &nbsp;
                    </div>
                </div>
                <div className='aisidebar-count-group-content'>
                    <div className='aisidebar-count-group-header'>
                        <div
                            className='aisidebar-count-group-title'
                            style={{
                                color: currentStep < 3
                                    ? 'silver'
                                    : currentStep === 3
                                        ? '#006aff'
                                        : '',
                            }}
                        >
                            Location
                        </div>
                        <Popup
                            trigger={open => (
                                <div className='aisidebar-count-group-header-help'>
                                    ?
                                </div>
                            )}
                            on={['click', 'hover']}
                            position="bottom center"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                        >
                            <div className='aisidebar-count-group-help'>
                                <div>
                                    Choose a location for the AI to count
                                </div>
                                <div>
                                    <b>All:</b> AI will look at everything on the page
                                </div>
                                <div>
                                    <b>Rectangle:</b> Draw a rectangle to bound AI output
                                </div>
                                <div>
                                    <b>Polygon:</b> Draw a polygon to bound AI output
                                </div>
                            </div>
                        </Popup>
                    </div>
                    <div className='aisidebar-count-group-body'>
                        <div className='aisidebar-count-group-3choices'>
                            <div className={'aisidebar-count-group-choice aisidebar-count-group-2choices-left '
                                + (autoCountLocation === 'auto' && pages[pageID]?.ai_prepped && ' aisidebar-count-group-choice-active ')
                                + ((currentStep < 3 || !pages[pageID]?.ai_prepped) && ' aisidebar-count-group-choice-disabled ')
                            }
                                onClick={() => {
                                    if (!AIMessages?.[pageID]?.autoCount?.running && currentStep >= 3) {
                                        handleTurnOffDrawing();
                                        setAutoCountLocation('auto')
                                    }
                                }}
                                style={{
                                    backgroundColor: currentStep === 3 && pages[pageID]?.ai_prepped && 'white',
                                    borderColor: currentStep === 3 && pages[pageID]?.ai_prepped && '#006aff',
                                }}
                            >
                                All
                            </div>
                            <div
                                className={'aisidebar-count-group-choice aisidebar-count-group-3choices-center '
                                    + (autoCountLocation === 'rectangle' && pages[pageID]?.ai_prepped && ' aisidebar-count-group-choice-active ')
                                    + ((currentStep < 3 || !pages[pageID]?.ai_prepped) && ' aisidebar-count-group-choice-disabled ')
                                }
                                onClick={() => {
                                    if (!AIMessages?.[pageID]?.autoCount?.running && currentStep >= 3) {
                                        handleTurnOffDrawing();
                                        setAutoCountLocation('rectangle')
                                    }
                                }}
                                style={{
                                    backgroundColor: currentStep === 3 && pages[pageID]?.ai_prepped && 'white',
                                    borderColor: currentStep === 3 && pages[pageID]?.ai_prepped && '#006aff',
                                }}
                            >
                                Rectangle
                            </div>
                            <div
                                className={'aisidebar-count-group-choice aisidebar-count-group-2choices-right '
                                    + (autoCountLocation === 'polygon' && pages[pageID]?.ai_prepped && ' aisidebar-count-group-choice-active ')
                                    + ((currentStep < 3 || !pages[pageID]?.ai_prepped) && ' aisidebar-count-group-choice-disabled ')
                                }
                                onClick={() => {
                                    if (!AIMessages?.[pageID]?.autoCount?.running && currentStep >= 3) {
                                        handleTurnOffDrawing();
                                        setAutoCountLocation('polygon')
                                    }
                                }}
                                style={{
                                    backgroundColor: currentStep === 3 && pages[pageID]?.ai_prepped && 'white',
                                    borderColor: currentStep === 3 && pages[pageID]?.ai_prepped && '#006aff',
                                }}
                            >
                                Polygon
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='aisidebar-count-group'
                style={{
                    backgroundColor: currentStep === 4
                        ? 'whitesmoke'
                        : AIMessages?.[pageID]?.autoCount?.running
                            ? 'aliceblue'
                            : '',
                }}
            >
                <div
                    className='aisidebar-count-group-progress-last'
                    style={{
                        borderColor: currentStep > 3 ? '#006AFF' : 'silver',
                    }}
                >
                    <div
                        className='aisidebar-count-group-progress-dot'
                        style={{
                            backgroundColor: currentStep > 4 ? '#006AFF' : 'white',
                            borderColor: currentStep >= 4 ? '#006AFF' : 'silver',
                            boxShadow: (currentStep == 4 || AIMessages?.[pageID]?.autoCount?.running) && '0px 0px 4px 1px #006AFF',
                        }}
                    >
                        &nbsp;
                    </div>
                </div>
                <div className='aisidebar-count-group-content'>
                    <div className='aisidebar-count-group-header'>
                        <div
                            className='aisidebar-count-group-title'
                            style={{
                                color: currentStep < 4
                                    ? 'silver'
                                    : currentStep === 4
                                        ? '#006aff'
                                        : '',
                            }}
                        >
                            Run model
                        </div>
                        <Popup
                            trigger={open => (
                                <div className='aisidebar-count-group-header-help'>
                                    ?
                                </div>
                            )}
                            on={['click', 'hover']}
                            position="bottom center"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                        >
                            <div className='aisidebar-count-group-help'>
                                <div>
                                    Start a model run based on your selections
                                </div>
                                <div>
                                    If you are using an example, select the example
                                </div>
                                <div>
                                    If you are bounding the location, draw a rectangle or polygon
                                </div>
                            </div>
                        </Popup>
                    </div>
                    <div className='aisidebar-count-group-body'>
                        <div
                            className={'aisidebar-count-auto-count-button '
                                + (currentStep === 4 && ' aisidebar-count-auto-count-button-active ')
                                + (currentStep === 5 && ' aisidebar-count-auto-count-button-running ')
                                + (currentStep < 4 && ' aisidebar-count-auto-count-button-disabled ')
                            }
                            onClick={() => {
                                setCurrentMeasurement(null);
                                handleAutoCount();
                            }}
                        >
                            {autoCountReference === 'auto'
                                ? autoCountLocation === 'auto'
                                    ? <>Run auto count</>
                                    : autoCountLocation === 'rectangle'
                                        ? <>Draw rectangle</>
                                        : <>Draw polygon</>
                                : autoCountReference === 'example'
                                    ? autoCountLocation === 'auto'
                                        ? <>Select example</>
                                        : autoCountLocation === 'rectangle'
                                            ? AIAutoCountRect?.[pageID]?.show
                                                ? <>Draw rectangle</>
                                                : <>Select example</>
                                            : AIAutoCountPoly?.[pageID]?.show
                                                ? <>Draw polygon</>
                                                : <>Select example</>
                                    : autoCountLocation === 'auto'
                                        ? <>Run auto count legend</>
                                        : autoCountLocation === 'rectangle'
                                            ? <>Draw rectangle</>
                                            : <>Draw polygon</>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

    return (
        <div className='aisidebar-count-container'>
            {pages[pageID]?.ai_prepped
                ? <>
                    <div className='navbar-aipopup-group'>
                        <div className='navbar-aipopup-group-header'>
                            <div className='aisidebar-count-group-title'>
                                Auto count
                            </div>
                        </div>

                        <div className='aisidebar-count-group-body'>
                            <div className='aisidebar-count-auto-count-option'>
                                <div>
                                    Reference
                                </div>
                                <select
                                    className='aisidebar-count-auto-count-select'
                                    id={'aisidebar-count-auto-count-reference'}
                                    value={autoCountReference}
                                    onChange={(e) => setAutoCountReference(e.target.value)}
                                >
                                    {/*<option value='auto'>Auto</option>*/}
                                    <option value='example'>Example</option>
                                    {AILegends && Object.keys(AILegends)?.length > 0 &&
                                        <option value='legend'>Legend</option>
                                    }
                                </select>

                                <Tooltip delayShow={500} anchorSelect='#aisidebar-count-auto-count-reference'>
                                    <div><b>Count reference</b></div>
                                    <div>Auto: Automatically group counts</div>
                                    <div>Example: Count based on an example you select</div>
                                    <div>Legend: Count based on a legend you select</div>
                                </Tooltip>
                            </div>

                            {autoCountReference === 'legend' &&
                                <div className='aisidebar-count-auto-count-option'>
                                    <div>
                                        Legend
                                    </div>
                                    <select
                                        className='aisidebar-count-auto-count-select'
                                        id='aisidebar-count-auto-count-legend'
                                        value={currentLegend}
                                        onChange={(e) => setCurrentLegend(e.target.value)}
                                    >
                                        {AILegends && Object.values(AILegends)?.sort((a, b) => b.id - a.id)?.map((legend, index) =>
                                            <option key={index} value={legend.id}>{legend.name}</option>
                                        )}
                                    </select>

                                    <Tooltip delayShow={500} anchorSelect='#aisidebar-count-auto-count-legend'>
                                        Select legend for the AI to reference
                                    </Tooltip>
                                </div>
                            }

                            <div className='aisidebar-count-auto-count-option'>
                                <div>
                                    Location
                                </div>
                                <select
                                    className='aisidebar-count-auto-count-select'
                                    id={'aisidebar-count-auto-count-location'}
                                    value={autoCountLocation}
                                    onChange={(e) => setAutoCountLocation(e.target.value)}
                                >
                                    <option value='auto'>Whole page</option>
                                    <option value='rectangle'>Rectangle</option>
                                    <option value='polygon'>Polygon</option>
                                </select>

                                <Tooltip delayShow={500} anchorSelect='#aisidebar-count-auto-count-location'>
                                    <div><b>Count location</b></div>
                                    <div>Whole page: AI will look at everything on the page</div>
                                    <div>Rectangle: Draw a rectangle to bound AI output</div>
                                    <div>Polygon: Draw a polygon to bound AI output</div>
                                </Tooltip>
                            </div>

                            <div className='aisidebar-count-auto-count-button-container'>
                                <button
                                    className='aisidebar-count-auto-count-button'
                                    onClick={() => {
                                        handleAutoCount();
                                    }}
                                >
                                    {autoCountReference === 'auto'
                                        ? autoCountLocation === 'auto'
                                            ? <>Run auto count</>
                                            : autoCountLocation === 'rectangle'
                                                ? <>Draw rectangle</>
                                                : <>Draw polygon</>
                                        : autoCountReference === 'example'
                                            ? autoCountLocation === 'auto'
                                                ? <>Select example</>
                                                : autoCountLocation === 'rectangle'
                                                    ? <>Select example & draw rect</>
                                                    : <>Select example & draw polygon</>
                                            : autoCountLocation === 'auto'
                                                ? <>Run auto count legend</>
                                                : autoCountLocation === 'rectangle'
                                                    ? <>Draw rectangle</>
                                                    : <>Draw polygon</>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </>
                : <>
                    <div className='navbar-aipopup-group'>
                        <div className='navbar-aipopup-group-header'>
                            <div className='navbar-aipopup-group-title'>
                                Prep page for counting
                            </div>
                        </div>
                        {AIMessages?.[pageID]?.prepLocalization?.running
                            ? <div className='navbar-aipopup-group-item'>
                                Prepping {prepTrade}

                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : <div className='navbar-aipopup-group-body'>
                                <div
                                    id='navbar-aipopup-item-prep-electrical'
                                    className='navbar-aipopup-group-item'
                                    onClick={() => {
                                        if (!AIMessages?.[pageID]?.prepLocalization?.running) {
                                            handlePrep('electrical');
                                        }
                                    }}
                                >
                                    Electrical
                                </div>

                                <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-prep-electrical'>
                                    Prep page for electrical symbols
                                </Tooltip>

                                <div
                                    id='navbar-aipopup-item-prep-landscaping'
                                    className='navbar-aipopup-group-item'
                                    onClick={() => {
                                        if (!AIMessages?.[pageID]?.prepLocalization?.running) {
                                            handlePrep('landscaping');
                                        }
                                    }}
                                >
                                    Landscaping
                                </div>

                                <Tooltip delayShow={500} anchorSelect='#navbar-aipopup-item-prep-landscaping'>
                                    Prep page for landscaping symbols
                                </Tooltip>
                            </div>
                        }
                    </div>
                </>
            }

            <hr className='navbar-aipopup-hr'></hr>

            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Legends
                    </div>

                    <div
                        className='aisidebar-count-legend-create-button'
                        id={'aisidebar-count-legend-create-button'}
                        onClick={() => handleCreateLegend()}
                    >
                        <IconSquareRoundedPlus size={20} stroke={1} />
                    </div>

                    <Tooltip delayShow={500} anchorSelect='#aisidebar-count-legend-create-button'>
                        Create legend
                    </Tooltip>
                </div>

                <div className='ailegend-popup-container'>
                    {AILegends && Object.keys(AILegends).length
                        ? <div className='aisidebar-count-legends'>
                            {Object.values(AILegends).sort((a, b) => b.id - a.id).map((legend, index) =>
                                <Legend key={index} legend={legend} />
                            )}
                        </div>
                        : <div>
                            No legends
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function Legend({ legend, AILegendViews, setAILegendViews }) {
    const auth = useSelector(selectAuth);

    const {
        pages,
        pageID,
        selectingLegend, setSelectingLegend,
        drawingLegend, setDrawingLegend,
        currentLegend, setCurrentLegend,
        drawingLegendEntry, setDrawingLegendEntry,
        drawingLegendEntryRun, setDrawingLegendEntryRun,
        setAILegends,
        handleTurnOffDrawing,
        setCurrentMeasurement,
    } = useContext(TakeoffContext);

    const [name, setName] = useState(legend.name); //legend['name']
    const [editingName, setEditingName] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showColumnsModal, setShowColumnsModal] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        //setShowDeleteModal(false);
        setOpenModal(false);
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' || e.key === 'Enter') {
                if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
                    document.activeElement.blur();
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const handleSaveName = () => {
        setAILegends(prev => ({
            ...prev,
            [legend.id]: {
                ...prev[legend.id],
                name: name,
            }
        }));

        axios({
            'method': 'put',
            'url': `${API_ROUTE}/api/aivirtuallegends/${legend.id}/`,
            'data': {
                'project': legend.project,
                'group': legend.group,
                'name': name,
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });

        setEditingName(false);
    }

    const handleDeleteLegend = () => {
        console.log('delete legend', legend.id);

        setAILegends(prev => {
            const newLegends = { ...prev };
            delete newLegends[legend.id];
            return newLegends;
        });

        axios({
            'method': 'delete',
            'url': `${API_ROUTE}/api/aivirtuallegends/${legend.id}/`,
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);

                setOpenModal(false);
                setShowDeleteModal(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div
            className='aisidebar-count-legend'
            style={{
                border: (currentLegend === legend.id) && '1px solid #006AFE',
                backgroundColor: (currentLegend === legend.id) && 'aliceblue',
            }}
        >
            <div
                className='aisidebar-count-legend-header'
                onContextMenu={(e) => {
                    e.preventDefault();
                    setOpenModal(true);
                }}
                onClick={() => {
                    setCurrentLegend(legend.id);
                }}
            >
                {editingName
                    ? <input
                        className='aisidebar-count-legend-name-edit'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={() => handleSaveName()}
                        autoFocus
                    />
                    : <div
                        className='aisidebar-count-legend-name'
                        id={`aisidebar-count-legend-name-${legend.id}`}
                        onDoubleClick={() => setEditingName(true)}
                        onContextMenu={(e) => {
                            e.preventDefault();
                            setOpenModal(true);
                        }}
                    >
                        {legend.name ? legend.name : 'No name'}
                    </div>
                }

                <Tooltip delayShow={500} anchorSelect={`#aisidebar-count-legend-name-${legend.id}`} positionStrategy='fixed' place='bottom-start'>
                    <div><b>{legend.name ? legend.name : 'No name'}</b></div>
                    <div>Double click to edit name</div>
                    <div>Right click for settings</div>
                </Tooltip>

                <ColumnsModal
                    show={showColumnsModal}
                    setShow={setShowColumnsModal}
                    legend={legend}
                />

                <DeleteLegendModal
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    handleDelete={handleDeleteLegend}
                    onHide={() => setShowDeleteModal(false)}
                />

                {legend?.entries?.length > 0
                    ? <div className='aisidebar-count-legend-buttons'>
                        <div
                            id='aisidebar-count-legend-view-toggle'
                            className="aisidebar-count-legend-button"
                            onClick={() => {
                                if (AILegendViews?.[legend.id] === 'view') {
                                    setAILegendViews(prev => ({
                                        ...prev,
                                        [legend.id]: 'hide',
                                    }));
                                } else if (AILegendViews?.[legend.id] === 'hide') {
                                    setAILegendViews(prev => ({
                                        ...prev,
                                        [legend.id]: 'preview',
                                    }));

                                } else if (AILegendViews?.[legend.id] === 'preview') {
                                    setAILegendViews(prev => ({
                                        ...prev,
                                        [legend.id]: 'view',
                                    }));
                                }
                            }}
                        >
                            {AILegendViews?.[legend.id] === 'hide'
                                ? <IconLayoutGrid size={20} stroke={1} />
                                : AILegendViews?.[legend.id] === 'preview'
                                    ? <IconListDetails size={20} stroke={1} />
                                    : <IconLayoutNavbarCollapse size={20} stroke={1} />
                            }
                        </div>

                        <Tooltip delayShow={500} anchorSelect='#aisidebar-count-legend-view-toggle'>
                            {AILegendViews?.[legend.id] === 'hide'
                                ? 'Preview legend symbols'
                                : AILegendViews?.[legend.id] === 'preview'
                                    ? 'View legend symbols'
                                    : 'Hide legend symbols'}
                        </Tooltip>

                        <Popup
                            trigger={
                                <div
                                    className="aisidebar-count-legend-button"
                                    id={'aisidebar-count-legend-settings-' + legend.id}
                                    style={{
                                        color: openModal && '#006AFE',
                                    }}
                                    onClick={() => setOpenModal(prev => !prev)}
                                >
                                    <IconSettings size={20} stroke={1} />
                                </div>
                            }
                            open={openModal}
                            on=''
                            onClose={closeModal}
                            position="bottom right"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                        >
                            <div className="aisidebar-count-legend-settings">
                                <div className='aisidebar-count-legend-settings-group'>
                                    <div className='aisidebar-count-legend-settings-group-body'>
                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            id={'aisidebar-count-legend-collapse-' + legend.id}
                                            onClick={() => {
                                                closeModal();
                                                setAILegendViews(prev => ({
                                                    ...prev,
                                                    [legend.id]: 'hide',
                                                }));
                                            }}
                                            style={{
                                                color: AILegendViews?.[legend.id] === 'hide' && '#006AFE',
                                            }}
                                        >
                                            <IconLayoutNavbarCollapse size={20} stroke={1} /> Collapse
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-collapse-' + legend.id}>
                                            Collapse legend
                                        </Tooltip>

                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            id={'aisidebar-count-legend-preview-' + legend.id}
                                            onClick={() => {
                                                closeModal();

                                                if (AILegendViews?.[legend.id] === 'preview') {
                                                    setAILegendViews(prev => ({
                                                        ...prev,
                                                        [legend.id]: 'hide',
                                                    }));
                                                } else {
                                                    setAILegendViews(prev => ({
                                                        ...prev,
                                                        [legend.id]: 'preview',
                                                    }));
                                                }
                                            }}
                                            style={{
                                                color: AILegendViews?.[legend.id] === 'preview' && '#006AFE',
                                            }}
                                        >
                                            <IconLayoutGrid size={20} stroke={1} /> Preview
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-preview-' + legend.id}>
                                            Preview legend symbols
                                        </Tooltip>

                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            id={'aisidebar-count-legend-' + legend.id}
                                            onClick={() => {
                                                if (AILegendViews?.[legend.id] === 'view') {
                                                    setAILegendViews(prev => ({
                                                        ...prev,
                                                        [legend.id]: 'hide',
                                                    }));
                                                } else {
                                                    setAILegendViews(prev => ({
                                                        ...prev,
                                                        [legend.id]: 'view'
                                                    }));
                                                }
                                                setOpenModal(false);
                                            }}
                                            style={{
                                                color: AILegendViews?.[legend.id] === 'view' && '#006AFE',
                                            }}
                                        >
                                            <IconListDetails size={20} stroke={1} /> View
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-' + legend.id}>
                                            View legend symbols
                                        </Tooltip>
                                    </div>
                                </div>

                                <hr className='aisidebar-count-legend-settings-hr'></hr>

                                <div className='aisidebar-count-legend-settings-group'>
                                    <div className='aisidebar-count-legend-settings-group-body'>
                                        <div
                                            id={'aisidebar-count-legend-populate-entry-' + legend.id}
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                setCurrentMeasurement(null);
                                                handleTurnOffDrawing();

                                                setDrawingLegendEntry(true);
                                                setCurrentLegend(legend.id);
                                                setOpenModal(false);
                                            }}
                                            style={{
                                                color: drawingLegendEntry && '#006AFE',
                                            }}
                                        >
                                            <IconSquareRoundedPlus size={20} stroke={1} /> Add entry
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-entry-' + legend.id}>
                                            <div><b>Add entry</b></div>
                                            <div>Draw a rectangle around a symbol to add it to the legend</div>
                                            <div>Or click on a symbol found by the model</div>
                                        </Tooltip>

                                        <div
                                            id={'aisidebar-count-legend-populate-entry-run-' + legend.id}
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                setCurrentMeasurement(null);
                                                handleTurnOffDrawing();

                                                setDrawingLegendEntryRun(true);
                                                setCurrentLegend(legend.id);
                                                setOpenModal(false);
                                            }}
                                            style={{
                                                color: drawingLegendEntryRun && '#006AFE',
                                            }}
                                        >
                                            <IconSquareRoundedPlus size={20} stroke={1} /> Add entry & run
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-entry-run-' + legend.id} place='right'>
                                            <div><b>Add entry & run</b></div>
                                            <div>Add an entry and re run the model for only that entry</div>
                                        </Tooltip>

                                        {pages[pageID]?.ai_prepped
                                            ? <>
                                                <div
                                                    id={'aisidebar-count-legend-populate-' + legend.id}
                                                    className='aisidebar-count-legend-settings-group-item'
                                                    onClick={() => {
                                                        setCurrentMeasurement(null);
                                                        handleTurnOffDrawing();

                                                        setSelectingLegend(true);
                                                        setCurrentLegend(legend.id);
                                                        setOpenModal(false);
                                                    }}
                                                    style={{
                                                        color: selectingLegend && '#006AFE',
                                                    }}
                                                >
                                                    <IconRobot size={20} stroke={1} /> Populate AI found
                                                </div>

                                                <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-' + legend.id} place='right'>
                                                    <div><b>Populate with AI found legends</b></div>
                                                    <div>Select a legend to populate the page with</div>
                                                </Tooltip>
                                            </>
                                            : <>
                                                <div
                                                    id={'aisidebar-count-legend-populate-' + legend.id}
                                                    className='aisidebar-count-legend-settings-group-item-disabled'
                                                >
                                                    <IconRobot size={20} stroke={1} /> Populate AI found
                                                </div>

                                                <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-' + legend.id} place='right'>
                                                    <div><b>Populate with AI found legends</b></div>
                                                    <div>Prep page for counting first</div>
                                                </Tooltip>
                                            </>
                                        }

                                        <div
                                            id={'aisidebar-count-legend-populate-manual-' + legend.id}
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                setCurrentMeasurement(null);
                                                handleTurnOffDrawing();

                                                setDrawingLegend(true);
                                                setCurrentLegend(legend.id);
                                                setOpenModal(false);
                                            }}
                                            style={{
                                                color: drawingLegend && '#006AFE',
                                            }}
                                        >
                                            <IconTablePlus size={20} stroke={1} /> Populate manually
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-manual-' + legend.id}>
                                            <div><b>Populate with manual drawing</b></div>
                                            <div>Draw a rectangle around the area you want to populate with</div>
                                        </Tooltip>
                                    </div>
                                </div>

                                <hr className='aisidebar-count-legend-settings-hr'></hr>

                                <div className='aisidebar-count-legend-settings-group'>
                                    <div className='aisidebar-count-legend-settings-group-body'>
                                        <div
                                            id={'aisidebar-count-legend-rename-' + legend.id}
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                setOpenModal(false);
                                                setEditingName(true);
                                            }}
                                        >
                                            <IconBaseline size={20} stroke={1} /> Rename
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-rename-' + legend.id}>
                                            Rename legend
                                        </Tooltip>

                                        <div
                                            id={'aisidebar-count-legend-rename-' + legend.id}
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                setOpenModal(false);
                                                setShowColumnsModal(true);
                                            }}
                                        >
                                            <IconSwitch3 size={20} stroke={1} /> Switch name column
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-rename-' + legend.id}>
                                            Switch the column in the table used for the name
                                        </Tooltip>

                                        <div
                                            id={'aisidebar-count-legend-delete-' + legend.id}
                                            className='aisidebar-count-legend-settings-group-item-delete'
                                            onClick={() => {
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            <IconTrashX size={20} stroke={1} /> Delete legend
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-delete-' + legend.id}>
                                            Delete legend
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </Popup>

                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-settings-' + legend.id}>
                            Settings
                        </Tooltip>
                    </div>
                    : <div className='aisidebar-count-legend-buttons-empty'>
                        <div
                            id={'aisidebar-count-legend-populate-entry-' + legend.id}
                            className='aisidebar-count-legend-settings-group-item'
                            onClick={() => {
                                setCurrentMeasurement(null);
                                handleTurnOffDrawing();

                                setDrawingLegendEntry(true);
                                setCurrentLegend(legend.id);
                                setOpenModal(false);
                            }}
                            style={{
                                color: drawingLegendEntry && '#006AFE',
                            }}
                        >
                            <IconSquareRoundedPlus size={20} stroke={1} />
                        </div>

                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-entry-' + legend.id} positionStrategy='fixed' style={{ zIndex: 10000 }} place='bottom-start'>
                            <div><b>Add entry</b></div>
                            <div>Draw a rectangle around a symbol to add it to the legend</div>
                            <div>Or click on a symbol found by the model</div>
                        </Tooltip>

                        {pages[pageID]?.ai_prepped
                            ? <>
                                <div
                                    id={'aisidebar-count-legend-populate-' + legend.id}
                                    className='aisidebar-count-legend-settings-group-item'
                                    onClick={() => {
                                        setCurrentMeasurement(null);
                                        handleTurnOffDrawing();

                                        setSelectingLegend(true);
                                        setCurrentLegend(legend.id);
                                        setOpenModal(false);
                                    }}
                                    style={{
                                        color: selectingLegend && '#006AFE',
                                    }}
                                >
                                    <IconRobot size={20} stroke={1} />
                                </div>

                                <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-' + legend.id} positionStrategy='fixed' style={{ zIndex: 10000 }} place='bottom-start'>
                                    <div><b>Populate with AI found legends</b></div>
                                    <div>Select a legend to populate the page with</div>
                                </Tooltip>
                            </>
                            : <>
                                <div
                                    id={'aisidebar-count-legend-populate-' + legend.id}
                                    className='aisidebar-count-legend-settings-group-item-disabled'
                                >
                                    <IconRobot size={20} stroke={1} />
                                </div>

                                <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-' + legend.id}>
                                    <div><b>Populate with AI found legends</b></div>
                                    <div>Prep page for counting first</div>
                                </Tooltip>
                            </>
                        }

                        <div
                            id={'aisidebar-count-legend-populate-manual-' + legend.id}
                            className='aisidebar-count-legend-settings-group-item'
                            onClick={() => {
                                setCurrentMeasurement(null);
                                handleTurnOffDrawing();

                                setDrawingLegend(true);
                                setCurrentLegend(legend.id);
                                setOpenModal(false);
                            }}
                            style={{
                                color: drawingLegend && '#006AFE',
                            }}
                        >
                            <IconTablePlus size={20} stroke={1} />
                        </div>

                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-manual-' + legend.id} positionStrategy='fixed' style={{ zIndex: 10000 }} place='bottom-start'>
                            <div><b>Populate with manual drawing</b></div>
                            <div>Draw a rectangle around the area you want to populate with</div>
                        </Tooltip>

                        <Popup
                            trigger={
                                <div
                                    className='aisidebar-count-legend-settings-group-item'
                                    id={'aisidebar-count-legend-settings-' + legend.id}
                                    style={{
                                        color: openModal && '#006AFE',
                                    }}
                                    onClick={() => setOpenModal(prev => !prev)}
                                >
                                    <IconSettings size={20} stroke={1} />
                                </div>
                            }
                            open={openModal}
                            on=''
                            onClose={closeModal}
                            position="bottom right"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                        >
                            <div className="aisidebar-count-legend-settings">
                                <div className='aisidebar-count-legend-settings-group'>
                                    <div className='aisidebar-count-legend-settings-group-body'>
                                        <div
                                            id={'aisidebar-count-legend-rename-' + legend.id}
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                setOpenModal(false);
                                                setEditingName(true);
                                            }}
                                        >
                                            <IconBaseline size={20} stroke={1} /> Rename
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-rename-' + legend.id}>
                                            Rename legend
                                        </Tooltip>

                                        <div
                                            id={'aisidebar-count-legend-delete-' + legend.id}
                                            className='aisidebar-count-legend-settings-group-item-delete'
                                            onClick={() => {
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            <IconTrashX size={20} stroke={1} /> Delete legend
                                        </div>

                                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-delete-' + legend.id}>
                                            Delete legend
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </Popup>
                    </div>
                }


                {/*!editingName &&
                    <div className='aisidebar-count-legend-buttons'>
                        <div
                            className='aisidebar-count-legend-button'
                            id={'aisidebar-count-legend-preview-' + legend.id}
                            onClick={() => {
                                if (AILegendViews?.[legend.id] === 'preview') {
                                    setView('hide');
                                } else {
                                    setView('preview');
                                }
                            }}
                            style={{
                                color: AILegendViews?.[legend.id] === 'preview' && '#006AFE',
                            }}
                        >
                            <IconLayoutGrid size={20} stroke={1} />
                        </div>

                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-preview-' + legend.id}>
                            Preview legend symbols
                        </Tooltip>

                        <div
                            className='aisidebar-count-legend-button'
                            id={'aisidebar-count-legend-AILegendViews?.[legend.id]-' + legend.id}
                            onClick={() => {
                                if (AILegendViews?.[legend.id] === 'view') {
                                    setView('hide');
                                } else {
                                    setView('view');
                                }
                            }}
                            style={{
                                color: AILegendViews?.[legend.id] === 'view' && '#006AFE',
                            }}
                        >
                            <IconListDetails size={20} stroke={1} />
                        </div>

                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-AILegendViews?.[legend.id]-' + legend.id}>
                            View legend symbols
                        </Tooltip>

                        <div
                            id={'aisidebar-count-legend-populate-' + legend.id}
                            className='aisidebar-count-legend-button'
                            onClick={() => {
                                setSelectingLegend(true);
                                setCurrentLegend(legend.id);
                            }}
                            style={{
                                color: selectingLegend && '#006AFE',
                            }}
                        >
                            <IconTablePlus size={20} stroke={1} />
                        </div>

                        <Tooltip delayShow={500} anchorSelect={'#aisidebar-count-legend-populate-' + legend.id}>
                            Populate with AI
                        </Tooltip>
                    </div>
                */}
            </div>

            {AILegendViews?.[legend.id] === 'preview' &&
                (legend?.entries?.length
                    ? <div className='aisidebar-count-legend-entries-preview'>
                        {legend.entries.sort((a, b) => b.id - a.id).map((entry, index) => (
                            <div key={index} className='aisidebar-count-legend-preview-entry'>
                                <img
                                    src={entry.file}
                                    className='aisidebar-count-legend-preview-entry-image'
                                />
                            </div>
                        ))}
                    </div>
                    : <div className='ailegend-popup-legend-no-entries'>
                        <div>No entries...</div>
                    </div>
                )
            }

            {AILegendViews?.[legend.id] === 'view' &&
                (legend?.entries?.length
                    ? <div className='ailegend-popup-legend-entries'>
                        {legend.entries.sort((a, b) => b.id - a.id).map((entry, index) =>
                            <LegendEntry key={index} entry={entry} />
                        )}
                    </div>
                    : <div className='ailegend-popup-legend-no-entries'>
                        <div>No entries...</div>
                    </div>
                )
            }
        </div >
    )
}

function DeleteLegendModal({ show, onHide, handleDelete }) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header>
                <div className="database-columns-modal-title">
                    Delete legend
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="database-columns-modal-body">
                    <div className="database-columns-modal-body-text">
                        Are you sure you want to delete this legend?
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div
                    className="delete-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDelete();
                    }}
                >
                    Delete
                </div>
                <div
                    className="default-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        onHide();
                    }}
                >
                    Cancel
                </div>
            </Modal.Footer>
        </Modal>
    )
}

function ColumnsModal({ show, setShow, legend }) {
    const auth = useSelector(selectAuth);

    const {
        setAILegends,
    } = useContext(TakeoffContext);

    const maxAttributes = legend?.entries?.reduce((acc, entry) => {
        //find the max number of atrributes for each entry
        const numAttributes = entry?.attributes?.length;
        if (numAttributes > acc) {
            return numAttributes;
        } else {
            return acc;
        }
    }, 0);

    const handleSwitchColumn = (index) => {
        setAILegends(prev => ({
            ...prev,
            [legend.id]: {
                ...prev[legend.id],
                entries: prev[legend.id].entries.map((entry) => {
                    if (entry.attributes.length > index) {
                        return {
                            ...entry,
                            text: entry.attributes[index].value,
                        }
                    } else {
                        return entry;
                    }
                })
            }
        }));

        setShow(false);

        axios({
            'method': 'post',
            'url': `${API_ROUTE}/api/update-virtual-legend/`,
            'data': {
                'legend': legend.id,
                'rows': legend.entries.map((entry) => {
                    if (entry.attributes.length > index) {
                        return {
                            'id': entry.id,
                            'text': entry.attributes[index].value,
                        }
                    }
                })
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            centered
        >
            <Modal.Header closeButton>
                <div className="database-columns-modal-title">
                    Switch column
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="database-columns-modal-body">
                    <div className="aisidebarcount-legends-column-modal-text">
                        <b>Select the column to use for the legend name.</b>
                        <div>If the column selected is not available for an entry, the entry will not be changed.</div>
                    </div>

                    <div
                        className="aisidebarcount-legends-column-modal-row"
                        style={{
                            gridTemplateColumns: `repeat(${maxAttributes}, 1fr)`
                        }}
                    >
                        {legend?.entries.find(entry => entry?.attributes?.length === maxAttributes)?.attributes?.map((attribute, index) =>
                            <div key={attribute.id} className="aisidebarcount-legends-column-modal-option"
                                onClick={() => handleSwitchColumn(index)}
                            >
                                &nbsp;# {index + 1}
                            </div>
                        )}
                    </div>

                    <div className="database-columns-modal-body-columns">
                        {maxAttributes > 1
                            ? legend?.entries?.map((entry, index) =>
                                <div
                                    key={index} className="aisidebarcount-legends-column-modal-row"
                                    style={{
                                        gridTemplateColumns: `repeat(${maxAttributes}, 1fr)`
                                    }}
                                >
                                    {entry?.attributes && Object.values(entry?.attributes)?.map((attribute, index) =>
                                        <div key={index} className="aisidebarcount-legends-column-modal-row-entry">
                                            {attribute?.value}
                                        </div>
                                    )}
                                </div>
                            )
                            : <div>
                                <div>
                                    No other columns
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div
                    className="default-button"
                    onClick={() => setShow(false)}
                >
                    Cancel
                </div>
                <div
                    className="default-button"
                    onClick={() => handleSave()}
                >
                    Save
                </div>
            </Modal.Footer>
        </Modal>
    )

}

function LegendEntry({ entry }) {
    const auth = useSelector(selectAuth);

    const {
        setAILegends,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [showAttributes, setShowAttributes] = useState(false);

    const [name, setName] = useState(entry.text);
    const [editingName, setEditingName] = useState(false);

    const MENU_ID = 'legend-entry-menu' + entry.id;

    const { show } = useContextMenu({
        id: MENU_ID
    });

    useEffect(() => {
        if (entry.text !== name) {
            setName(entry.text);
        }
    }, [entry.text]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
                    document.activeElement.blur();
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const handleSave = (nameIn) => {
        setAILegends(prev => ({
            ...prev,
            [entry.virtual_legend]: {
                ...prev[entry.virtual_legend],
                entries: prev[entry.virtual_legend].entries.map((e) => {
                    if (e.id === entry.id) {
                        return {
                            ...e,
                            text: nameIn || name,
                        }
                    } else {
                        return e;
                    }
                })
            }
        }));

        axios({
            'method': 'put',
            'url': `${API_ROUTE}/api/aivirtuallegendentries/${entry.id}/`,
            'data': {
                'group': entry.group,
                'virtual_legend': entry.virtual_legend,
                'text': nameIn || name,
                'x1': entry.x1,
                'y1': entry.y1,
                'x2': entry.x2,
                'y2': entry.y2,
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handeDelete = () => {
        setAILegends(prev => ({
            ...prev,
            [entry.virtual_legend]: {
                ...prev[entry.virtual_legend],
                entries: prev[entry.virtual_legend].entries.filter((e) => e.id !== entry.id)
            }
        }));

        axios({
            'method': 'delete',
            'url': `${API_ROUTE}/api/aivirtuallegendentries/${entry.id}/`,
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const trackVisibility = (visible) => {
        setShowContextMenu(visible);
    }

    return (
        <div
            className='ailegend-popup-legend-entry'
            onContextMenu={(e) => show({ event: e })}
            style={{
                border: showContextMenu ? '1px solid #006AFE' : '1px solid transparent',
                backgroundColor: showContextMenu && 'whitesmoke',
            }}
        >
            <div
                className='ailegend-popup-legend-entry-image-container'
            >
                {entry.file
                    ? <img
                        src={entry.file}
                        className='ailegend-popup-legend-entry-image'
                    />
                    : <div>
                        <Blocks
                            visible={true}
                            height="40"
                            width="20"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                }
            </div>

            {editingName
                ? <TextareaAutosize
                    className='aisidebar-count-legend-entry-text-edit'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={() => {
                        handleSave();
                        setEditingName(false);
                    }}
                    autoFocus
                    minRows={1}
                    maxRows={5}
                /> : <TextareaAutosize
                    className='aisidebar-count-legend-entry-text'
                    id={'legend-entry-text-' + entry.id}
                    onDoubleClick={() => setEditingName(true)}
                    readOnly
                    minRows={1}
                    maxRows={5}
                    value={name}
                />
            }

            <Tooltip delayShow={500} anchorSelect={'#legend-entry-text-' + entry.id}>
                Double click to edit name
            </Tooltip>

            <Menu
                id={MENU_ID}
                onVisibilityChange={trackVisibility}
                theme='bobyard-light'
            >
                <Item onMouseOver={() => setShowAttributes(true)} onMouseLeave={() => setShowAttributes(false)}>
                    <div className='aisidebar-legend-entry-menu-attribute-container'>
                        <div className='aisidebar-legend-entry-menu-item'>
                            <IconBaseline size={20} stroke={1} /> Switch column
                        </div>

                        {showAttributes &&
                            <div
                                className='aisidebar-legend-entry-menu-attributes'
                                style={{
                                    left: takeoffSettings?.ai_sidebar_location === 'left' && '100%',
                                    right: takeoffSettings?.ai_sidebar_location === 'right' && '100%',
                                }}
                            >
                                {entry?.attributes?.map((attribute, index) =>
                                    <div
                                        key={index}
                                        className='aisidebar-legend-entry-menu-attribute'
                                        onClick={() => {
                                            handleSave(attribute.value);
                                            setName(attribute.value);
                                        }}
                                    >
                                        <div className='aisidebar-legend-entry-menu-attribute-title'>
                                            {attribute.title}
                                        </div>
                                        <div>{attribute.value}</div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </Item>
                <Item onClick={() => setEditingName(true)}>
                    <div className='aisidebar-legend-entry-menu-item'>
                        <IconBaseline size={20} stroke={1} /> Edit name
                    </div>
                </Item>
                <Item onClick={() => handeDelete()}>
                    <div className='aisidebar-legend-entry-menu-delete'>
                        <IconTrashX size={20} stroke={1} /> Delete
                    </div>
                </Item>
            </Menu>
        </div>
    )
}