import { Html } from "react-konva-utils";
import { Rect } from "react-konva";

import '../../styles/ExportLegend.css';
import { useContext, useEffect, useMemo, useState } from "react";
import { TakeoffContext } from "../../helper/Context";
import { UOM, UOM_Display, UOM_Measured_Default, calculateValue } from '../../helper/UnitConversions';
import { API_ROUTE } from "../../..";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../redux/slices/authSlice";
import axios from "axios";

export default function ExportLegend({ }) {
    const auth = useSelector(selectAuth);

    const {
        groups,
        pageID,
        measurements,
        tree,
        pages, setPages,
    } = useContext(TakeoffContext);

    const [location, setLocation] = useState({
        x: pages[pageID]?.export_legend_x || 0,
        y: pages[pageID]?.export_legend_y || 0,
    })

    useEffect(() => {
        setLocation({
            x: pages[pageID]?.export_legend_x || 0,
            y: pages[pageID]?.export_legend_y || 0,
        })
    }, [pages[pageID]?.export_legend_x, pages[pageID]?.export_legend_y, pageID])

    const handleUpdatePage = (newPage) => {
        setPages(prev => ({
            ...prev,
            [newPage.id]: newPage,
        }))

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${newPage.id}/`,
            data: {
                'project': newPage.project,
                'export_legend_x': newPage.export_legend_x,
                'export_legend_y': newPage.export_legend_y,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const width = document.getElementById('export-legend-container')?.offsetWidth || 500;
    const height = document.getElementById('export-legend-container')?.offsetHeight || 500;

    return (
        <>
            <Html>
                <div
                    className="export-legend-container"
                    id="export-legend-container"
                    style={{
                        position: 'absolute',
                        top: location.y,
                        left: location.x,
                    }}
                >
                    <div className="export-legend-body">
                        <div className="export-legend-header">
                            Legend
                        </div>
                        <div className="export-legend-content">
                            {tree?.map((item, index) => {
                                if (item.id.includes('group-')) {
                                    return (
                                        <Group
                                            groups={groups}
                                            measurements={measurements}
                                            pages={pages}
                                            key={item.id}
                                            item={item}
                                        />
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </Html>

            <Rect
                x={location.x}
                y={location.y}
                width={width}
                height={height}
                opacity={0}

                draggable
                onDragMove={(e) => {
                    console.log(e);
                    e.evt.stopPropagation();
                    setLocation({
                        x: e.target.x(),
                        y: e.target.y(),
                    })
                }}
                onDragEnd={(e) => {
                    console.log(e);
                    e.evt.stopPropagation();
                    handleUpdatePage({
                        ...pages[pageID],
                        export_legend_x: e.target.x(),
                        export_legend_y: e.target.y(),
                    })
                }}
            />
        </>
    );
}

const Group = ({ groups, measurements, pages, item }) => {
    if (!item.id?.includes('group-')) return null;

    const group = groups[item.id?.split('-')[1]];

    const most_common_uom = useMemo(() => {
        //loop through all measurements and add their uom to set
        let uoms = {}
        Object.values(measurements).filter(m => m?.path?.includes('group-' + group.id)).forEach(m => {
            if (m?.uom) {
                if (!uoms[m?.uom]) uoms[m?.uom] = 0
                uoms[m?.uom] += 1
            } else {
                if (!uoms[UOM_Measured_Default[m?.type]]) uoms[UOM_Measured_Default[m?.type]] = 0
                uoms[UOM_Measured_Default[m?.type]] += 1
            }
        })

        //console.log(uoms)

        if (Object.keys(uoms).length === 0) return []

        //return the 1 uom that appears the most
        return Object.keys(uoms)?.reduce((a, b) => uoms[a] > uoms[b] ? a : b)
    }, [measurements, group])

    const get_uom_total = (uom) => {
        let total = 0
        Object.values(measurements).filter(m => m?.path?.includes('group-' + group.id)).forEach(m => {
            if (m?.uom === uom || !m?.uom && UOM_Measured_Default[m?.type] === uom) {
                total += calculateValue(m, pages[m?.page]?.scale)
            }
        })

        return total
    }

    return (
        <div className="export-legend-group" key={item.id}>
            <div className="export-legend-group-content">
                <div className="export-legend-group-name-color">
                    <div className="export-legend-group-color" style={{ backgroundColor: group.color }}>
                        &nbsp;
                    </div>
                    <div className="export-legend-group-name">
                        {group.name}
                    </div>
                </div>
                <div className="export-legend-group-total">
                    {group.uom && get_uom_total(group.uom)
                        ? <nobr>
                            {get_uom_total(group.uom).toFixed(2)} {UOM_Display[group.uom]}
                        </nobr>
                        : <nobr>
                            {get_uom_total(most_common_uom).toFixed(2)} {UOM_Display[most_common_uom]}
                        </nobr>
                    }
                </div>
            </div>

            {!item.collapsed &&
                <div className="export-legend-group-children">
                    {item.children?.map((child, index) => {
                        if (child.id.includes('group-')) {
                            return (
                                <Group
                                    key={child.id}
                                    item={child}
                                    measurements={measurements}
                                    groups={groups}
                                    pages={pages}
                                />
                            )
                        }
                    })}
                </div>
            }
        </div>
    )
}