import clsx from "clsx"
import React, { forwardRef, useContext, useState } from "react"
import "./FolderTreeItemWrapper.css"
import { MeasurementSideBarContext, TakeoffContext } from "../../../helper/Context"

function flattenParents(parent) {
  if (!parent) return []
  return [...flattenParents(parent.parent), parent]
}

export const FolderTreeItemWrapper = forwardRef((props, ref) => {
  const {
    clone,
    depth,
    disableSelection,
    disableInteraction,
    disableSorting,
    ghost,
    handleProps,
    indentationWidth,
    indicator,
    collapsed,
    onCollapse,
    onRemove,
    item,
    wrapperRef,
    style,
    isLast,
    parent,
    hideCollapseButton,
    childCount,
    manualDrag,
    showDragHandle,
    disableCollapseOnItemClick,
    className,
    contentClassName,
    isOver,
    isOverParent,
    measurementModal,
    ...rest
  } = props

  const {
    measurements, groups,
    search,
    filterPages, filterLength, filterArea, filterVolume, filterCount,
    currentMeasurement, setCurrentMeasurement,
    currentGroup, setCurrentGroup,
    setShowDetails,
    handleClickGroup, handleClickMeasurement,
    selectedGroups,
    selectedMeasurements,
  } = useContext(TakeoffContext);

  const {
    draggingID, setDraggingID,
    hoveringGroup,
  } = useContext(MeasurementSideBarContext);

  const flattenedParents = flattenParents(parent)

  const matchesSearch = () => {
    if (item.id.includes('group')) {
      let group = groups[item.id.split('-')[1]];
      if (!group) return false;

      if (!search) return true;

      if (group?.name?.toLowerCase().includes(search.toLowerCase())) return true;

      let path = group?.path.split('/').slice(0, -1);

      for (let i = 0; i < path.length; i++) {
        if (path[i].includes('group')) {
          if (groups[path[i].split('-')[1]]?.name?.toLowerCase().includes(search.toLowerCase())) return true;
        }
      }

      let hits = [
        ...Object.values(measurements).filter((m) => m.name?.toLowerCase().includes(search.toLowerCase()) || m.type?.toLowerCase().includes(search.toLowerCase())),
        ...Object.values(groups).filter((g) => g.name.toLowerCase().includes(search.toLowerCase())),
      ]

      for (let i = 0; i < hits.length; i++) {
        if (hits[i].path.includes('group-' + group.id)) return true;
      }

      return false;
    } else if (item.id.includes('measurement')) {
      let measurement = measurements[item.id.split('-')[1]];

      if (!measurement) return false;

      /*if (measurement.type === 'polygon' || measurement.type === 'rectangle' || measurement.type === 'circle') {
        if (!filterVolume && measurement?.depth) return false;
        if (!filterArea && !measurement?.depth) return false;
      } else if (measurement.type === 'line') {
        if (!filterLength && !measurement?.height) return false;
        if (!filterArea && !measurement?.depth) return false;
      } else if (measurement.type === 'count') {
        if (!filterCount) return false;
      }*/

      if (!search) return true;

      if (measurement?.name?.toLowerCase().includes(search.toLowerCase())) return true;
      if (measurement?.type?.toLowerCase().includes(search.toLowerCase())) return true;

      let path = measurements[measurement.id]?.path.split('/').slice(0, -1);

      for (let i = 0; i < path.length; i++) {
        if (path[i].includes('group')) {
          console.log(groups[path[i].split('-')[1]]?.name);
          if (groups[path[i].split('-')[1]]?.name?.toLowerCase().includes(search.toLowerCase())) return true;
        }
      }

      return false;
    } else if (item.id.includes('placeholder')) {
      //the parent is a group, it the parent should be shown, show as well
      if (item.parent?.id.includes('group')) {
        let path = item.parent?.path.split('/').slice(0, -1);
        for (let i = 0; i < path.length; i++) {
          if (path[i].includes('group')) {
            if (groups[path[i].split('-')[1]]?.name?.toLowerCase().includes(search.toLowerCase())) return true;
          }
        }
      }

      return false;
    } else {
      return true;
    }
  }

  if (!matchesSearch()) return null;

  return (
    <li
      {...rest}
      className={clsx(
        "takeoff-tree-dnd-sortable-tree_folder_wrapper",
        clone && "takeoff-tree-dnd-sortable-tree_folder_clone",
        ghost && "takeoff-tree-dnd-sortable-tree_folder_ghost",
        disableSelection && "takeoff-tree-dnd-sortable-tree_folder_disable-selection",
        disableInteraction && "takeoff-tree-dnd-sortable-tree_folder_disable-interaction",
        className,
        (item?.id.includes('group') && (item?.id == ('group-' + currentGroup) || selectedGroups.includes(Number(item?.id.split('-')[1])))) && 'takeoff-tree-dnd-sortable-tree_folder_wrapper-current-group',
        (item?.id.includes('measurement') && (item?.id == ('measurement-' + currentMeasurement) || selectedMeasurements.includes(Number(item?.id.split('-')[1])))) && 'takeoff-tree-dnd-sortable-tree_folder_wrapper-current-measurement',
      )}
      ref={wrapperRef}
      style={{
        ...style,
        border: item?.id.includes('group') && String(item?.id) === ('group-' + currentGroup)
          && '1px solid ' + groups[currentGroup]?.color,
        height: (!item?.id.includes('measurement')
          || (item?.id.includes('measurement') && (measurements[String(item.id.split('-')[1])]?.type != 'count'))) && '32px',
      }}
      onClick={(e) => {
        if (item.id.includes('group')) {
          handleClickGroup(e, groups[Number(item.id.split('-')[1])]);
          if (!e.shiftKey && !e.ctrlKey && !e.metaKey && !e.commandKey) {
            setCurrentGroup(Number(item.id.split('-')[1]));
          }
        } else if (item.id.includes('measurement')) {
          setCurrentMeasurement(Number(item.id.split('-')[1]));
          handleClickMeasurement(e, measurements[Number(item.id.split('-')[1])]);
          setShowDetails(true);
        }
      }}
    >
      {flattenedParents?.length > 0 && flattenedParents.slice(1).map((local_item, i) => (
        <div
          key={local_item.id}
          className={
            local_item.isLast
              ? "takeoff-tree-takeoff-tree-dnd-sortable-tree_folder_line-last"
              : "takeoff-tree-dnd-sortable-tree_folder_line"
          }
          style={{
            borderColor: hoveringGroup && local_item?.parentId === hoveringGroup
              ? groups[local_item?.parentId.split('-')[1]]?.color
              : draggingID && !draggingID?.includes('dot')
                ? local_item?.parentId
                  ? groups[local_item?.parentId.split('-')[1]]?.color
                  : '#9DD9F3'
                : 'whitesmoke',
            borderRightWidth: hoveringGroup && local_item?.parentId === hoveringGroup
              ? '2px'
              : draggingID && !draggingID?.includes('dot')
                ? '2px'
                : '1px',
            width: i === 0
              ? item?.id.includes('group') && String(item?.id) === ('group-' + currentGroup)
                ? '14px'
                : '15px'
              : '10px',
            minWidth: i === 0
              ? item?.id.includes('group') && String(item?.id) === ('group-' + currentGroup)
                ? '14px'
                : '15px'
              : '10px',
          }}
        >
          &nbsp;
        </div>
      ))}

      {flattenedParents?.length > 0 &&
        <div
          className={
            isLast
              ? "takeoff-tree-dnd-sortable-tree_folder_line-to_self-last"
              : "takeoff-tree-dnd-sortable-tree_folder_line-to_self"
          }
          style={{
            borderColor: hoveringGroup && parent && parent.id === hoveringGroup && !draggingID?.includes('dot')
              ? groups[parent.id.split('-')[1]]?.color
              : draggingID && !draggingID?.includes('dot')
                ? item?.parentId
                  ? groups[item?.parentId.split('-')[1]]?.color
                  : '9DD9F3'
                : 'whitesmoke',
            borderRightWidth: hoveringGroup && parent && parent.id === hoveringGroup && !draggingID?.includes('dot')
              ? '2px'
              : draggingID && !draggingID?.includes('dot')
                ? '2px'
                : '1px',
            width: flattenedParents?.length === 1
              ? item?.id.includes('group') && String(item?.id) === ('group-' + currentGroup)
                ? '14px'
                : '15px'
              : '10px',
            minWidth: flattenedParents?.length === 1
              ? item?.id.includes('group') && String(item?.id) === ('group-' + currentGroup)
                ? '14px'
                : '15px'
              : '10px',
          }}
        >
          &nbsp;
        </div >
      }

      <div
        className={clsx("takeoff-tree-dnd-sortable-tree_folder_tree-item", contentClassName)}
        style={{
          height: (!item?.id.includes('measurement')
            || (item?.id.includes('measurement') && (measurements[String(item.id.split('-')[1])]?.type != 'count'))) && '32px',
        }}
        ref={ref}
        {...(manualDrag ? undefined : handleProps)}
        onClick={disableCollapseOnItemClick ? undefined : onCollapse}
      >
        {props.children}
      </div>
    </li >
  )
})
