import { TakeoffContext } from "../../helper/Context";
import { useContext, useState } from "react";

import { API_ROUTE } from "../../..";
import { Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../redux/slices/authSlice";
import DeleteButton from "../../../components/DeleteButton";
import DefaultButton from "../../../components/DefaultButton";
import { Blocks } from "react-loader-spinner";

export default function DeleteGroupModal({
    showDeleteGroupModal, setShowDeleteGroupModal,
}) {
    const auth = useSelector(selectAuth)

    const {
        project,
        deleteGroup, setDeleteGroup,
        pageID,
        setMeasurements,
        groups, setGroups,
        setTree,
        currentGroup, setCurrentGroup,
        selectedGroups, setSelectedGroups,
    } = useContext(TakeoffContext);

    const [deleting, setDeleting] = useState(false);

    const handleDeleteGroup = () => {
        setDeleting(true);

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/group/`,
            data: {
                page: pageID,
                id: deleteGroup,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log(res);

                setShowDeleteGroupModal(false);
                setMeasurements(res.data.measurements);
                setGroups(res.data.groups);
                setTree(res.data.tree);

                if (currentGroup === deleteGroup) {
                    setCurrentGroup(null);
                }

                setDeleteGroup(null);
                setDeleting(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleDeleteGroups = () => {
        setDeleting(true);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/delete-selected-groups/`,
            data: {
                project: project.id,
                page: pageID,
                groups: [...(new Set(selectedGroups))],
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log(res);

                setShowDeleteGroupModal(false);
                setSelectedGroups([]);
                setCurrentGroup(null);
                setMeasurements(res.data.measurements);
                setGroups(res.data.groups);
                setTree(res.data.tree);

                setDeleteGroup(null);
                setDeleting(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    if (new Set(selectedGroups).size > 1) {
        return (
            <Modal
                show={showDeleteGroupModal}
                onHide={() => setShowDeleteGroupModal(false)}
                size='small'
            >
                <Modal.Header>
                    <Modal.Title>
                        <div className="deletemeasurementmodal-title">
                            Delete All Selected Groups
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        You have selected <b>{new Set(selectedGroups).size}</b> groups!
                    </div>

                    <div>
                        Are you sure you want to delete all of them and their subgroups and measurements?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {deleting
                        ? <>
                            <div className="deletemeasurementmodal-loading">
                                Deleting... <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                        </>
                        : <>
                            <DeleteButton
                                handleClick={() => handleDeleteGroups()}
                            >
                                Delete All
                            </DeleteButton>
                            <DefaultButton
                                handleClick={() => setShowDeleteGroupModal(false)}
                            >
                                Cancel
                            </DefaultButton>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <Modal
            show={showDeleteGroupModal}
            onHide={() => setShowDeleteGroupModal(false)}
            size='small'
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="deletemeasurementmodal-title">
                        Delete Group
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Are you sure you want to delete {groups[deleteGroup].name} and all of its subgroups and measurements?
                </div>
            </Modal.Body>
            <Modal.Footer>
                {deleting
                    ? <>
                        <div className="deletemeasurementmodal-loading">
                            Deleting... <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                    </>
                    : <>
                        <DeleteButton
                            handleClick={() => handleDeleteGroup()}
                        >
                            Delete All
                        </DeleteButton>
                        <DefaultButton
                            handleClick={() => setShowDeleteGroupModal(false)}
                        >
                            Cancel
                        </DefaultButton>
                    </>
                }
            </Modal.Footer>
        </Modal>
    )
}