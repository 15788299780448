import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import { API_ROUTE } from "../../../index";

export default function CircleConstructor({ countDots, setCountDots, countHistory, setCountHistory }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        selectedGroups,
        createMeasurement,
        createMeasurements,
        currentHistory, setCurrentHistory,
        history, setHistory,
        setDrawingCircle,
        setMeasurements,
        handleChangeFilter,
        stopWatch,
        snaps, setSnaps,
        stageRef,
    } = useContext(TakeoffContext);

    const [center, setCenter] = useState({ x: 0, y: 0 });
    const [outerPoint, setOuterPoint] = useState(null);
    const [radius, setRadius] = useState(0);

    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const [showSnap, setShowSnap] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Shift") {
                e.preventDefault();
                e.stopPropagation();
                setShowSnap(true);
            }
        }

        const handleKeyUp = (e) => {
            if (e.key === "Shift") {
                e.preventDefault();
                e.stopPropagation();
                setShowSnap(false);
            }
        }

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);


        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        }
    }, []);

    const addCircle = (circle) => {
        handleChangeFilter('include_area', true);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'type': 'circle',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                ['circle']: {
                    x: circle.x,
                    y: circle.y,
                    radius: circle.radius,
                },
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/circle/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup ? currentGroup : null,
                'groups': [...(currentGroup ? new Set([currentGroup, ...selectedGroups]) : new Set(selectedGroups))],
                'x': circle.x,
                'y': circle.y,
                'radius': circle.radius,
                'time': stopWatch.totalSeconds,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                //createMeasurement(response.data, false, tempName);

                createMeasurements(response.data, tempName);

                setTimeout(() => {
                    const element = document.getElementById("measurement-" + response.data.measurement.id);
                    element?.scrollIntoView();
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            });

        stopWatch.reset();
    }

    let closestSnap = [];

    if (pages?.[pageID]?.zoom && cursorPosition.x && cursorPosition.y && showSnap && snaps.length > 0) {
        closestSnap = snaps.reduce((prev, curr) => {
            const prevDistance = Math.sqrt((prev[0] - cursorPosition.x) ** 2 + (prev[1] - cursorPosition.y) ** 2);
            const currDistance = Math.sqrt((curr[0] - cursorPosition.x) ** 2 + (curr[1] - cursorPosition.y) ** 2);
            return prevDistance < currDistance ? prev : curr;
        })
    }

    return (
        <>
            {center.x && center.y && radius ?
                <Circle
                    x={center.x}
                    y={center.y}
                    radius={showSnap ? Math.sqrt(Math.pow(closestSnap[0] - center.x, 2) + Math.pow(closestSnap[1] - center.y, 2)) : radius}
                    fill={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                    opacity={0.3}
                />
                : null}

            {center.x && center.y ?
                <Circle
                    x={center.x}
                    y={center.y}
                    radius={5 / pages[pageID].zoom}
                    fill={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                />
                : null}

            {center.x && center.y && outerPoint ?
                <Line
                    strokeWidth={2 / pages[pageID].zoom}
                    points={[center.x, center.y, showSnap ? closestSnap[0] : outerPoint.x, showSnap ? closestSnap[1] : outerPoint.y]}
                    stroke={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                />
                : null}

            {showSnap && closestSnap.length > 0 &&
                <>
                    <Circle
                        x={closestSnap[0]}
                        y={closestSnap[1]}
                        radius={2 / pages[pageID].zoom}
                        fill="#006aff"
                        stroke="black"
                        strokeWidth={1 / pages[pageID].zoom}
                    />

                    <Line
                        strokeWidth={1 / pages[pageID].zoom}
                        dash={[5, 5]}
                        points={[closestSnap[0], closestSnap[1], cursorPosition.x, cursorPosition.y]}
                        stroke="#006aff"
                    />
                </>
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseEnter={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"}
                //onMouseOut={(e) => e.target.getStage().container().style.cursor = "default"}
                onMouseMove={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    setCursorPosition({ x, y });

                    if (center.x) {
                        setOuterPoint({ x, y });
                        setRadius(Math.sqrt(Math.pow(x - center.x, 2) + Math.pow(y - center.y, 2)));
                    }
                }}
                onClick={(e) => {
                    if (e.evt.button !== 0) return;
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    if (!center.x && !center.y) {
                        if (showSnap) {
                            setCenter({ x: closestSnap[0], y: closestSnap[1] });
                        } else {
                            setCenter({ x, y });
                        }
                    } else {
                        if (showSnap) {
                            addCircle({
                                x: center.x,
                                y: center.y,
                                radius: Math.sqrt(Math.pow(closestSnap[0] - center.x, 2) + Math.pow(closestSnap[1] - center.y, 2)),
                            });
                        } else {
                            addCircle({
                                x: center.x,
                                y: center.y,
                                radius: Math.sqrt(Math.pow(x - center.x, 2) + Math.pow(y - center.y, 2)),
                            });
                        }

                        setCenter({ x: 0, y: 0 });
                        setOuterPoint(null);
                        setRadius(0);
                    }
                }}
            />
        </>
    );
}