import { useEffect, useRef, useState } from "react";
import { Image, Layer, Rect, Stage, Group, Circle, Transformer } from "react-konva";
import useImage from "use-image";

import '../styles/Plans.css';
import { PROXYURL } from "../..";
import { Blocks } from "react-loader-spinner";

export default function Plans({ page1, page2, stageRef, zoom, setZoom, position, setPosition }) {
    const [red, redStatus] = useImage(PROXYURL + page1?.red_on_white, "Anonymous", 'origin');
    const [blue, blueStatus] = useImage(PROXYURL + page2?.blue_transparent, "Anonymous", 'origin');

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === "Escape") {
                setSelected(false);
            }
        }

        window.addEventListener("keydown", handleEscape);

        return () => {
            window.removeEventListener("keydown", handleEscape);
        }
    }, []);

    const handleWheel = (e) => {
        e.evt.preventDefault();

        if (e.evt.ctrlKey) {
            e.evt.preventDefault();

            let scaleBy = 1.05;

            if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
                scaleBy = 0.95;
            }

            const stage = e.target.getStage();

            const oldScale = stage.scaleX();

            const mousePointTo = {
                x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
                y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
            };

            const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

            setZoom(newScale);

            const newPos = {
                x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
                y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
            };

            setPosition(newPos);
        } else {
            const stage = e.target.getStage();
            const newPosition = stage.position();

            setPosition({
                x: newPosition.x - e.evt.deltaX,
                y: newPosition.y - e.evt.deltaY,
            });
        }
    };

    if (redStatus === "loading" || blueStatus === "loading") {
        return (
            <div className="takeoffplan-loading-container">
                <Blocks
                    visible={true}
                    height="60"
                    width="60"
                    color="#006AFE"
                    ariaLabel="blocks-loading"
                    radius="10"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                />
            </div>
        );
    }

    return (
        <div className="comparepages-plans-container">
            <Stage
                width={window.innerWidth}
                height={window.innerHeight}
                scaleX={zoom}
                scaleY={zoom}
                x={position.x}
                y={position.y}
                ref={stageRef}
                draggable={true}
                onWheel={(e) => handleWheel(e)}
            >
                <Layer>
                    <Rect
                        x={0 - position.x / zoom - 250}
                        y={0 - position.y / zoom - 250}
                        width={window.innerWidth / zoom + 500}
                        height={window.innerHeight / zoom + 500}
                        fill="rgb(115, 147, 179, 0.3)"
                    />
                </Layer>
                <Layer>
                    {red &&
                        <Image
                            image={red}
                            x={0}
                            y={0}
                        />
                    }
                    {blue &&
                        <OverImage
                            page={page2}
                            image={blue}
                            position={position}
                            zoom={zoom}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    }
                </Layer>
            </Stage>
        </div>
    );
}

function OverImage({ page, image, position, zoom, selected, setSelected }) {
    const [width, setWidth] = useState(page.width);
    const [height, setHeight] = useState(page.height);

    const [location, setLocation] = useState({ x: 0, y: 0 });

    const shapeRef = useRef();
    const trRef = useRef();

    useEffect(() => {
        if (selected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selected]);

    return (
        <>
            <Group
                draggable={selected}
                x={location.x}
                y={location.y}
                onDragMove={(e) => {
                    setLocation({
                        x: e.target.x(),
                        y: e.target.y()
                    })
                }}
            >
                <Image
                    onClick={() => setSelected(true)}
                    image={image}
                    ref={shapeRef}
                    onTransformEnd={e => {
                        const node = shapeRef.current;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        // we will reset it back
                        node.scaleX(1);
                        node.scaleY(1);
                        node.width(Math.max(5, node.width() * scaleX));
                        node.height(Math.max(node.height() * scaleY));
                    }}
                />

                {selected &&
                    <Transformer
                        ref={trRef}
                        rotateEnabled={false}
                        keepRatio={true}
                        enabledAnchors={[
                            'top-left',
                            'top-right',
                            'bottom-left',
                            'bottom-right',
                        ]}
                        boundBoxFunc={(oldBox, newBox) => {
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        }}
                    />
                }
            </Group>
        </>
    )
}