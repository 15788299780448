import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import { API_ROUTE } from '../index';
import ActiveButton from '../components/ActiveButton';

import "./styles/DashboardCreateProjectModal.css";
import { IconX } from '@tabler/icons-react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Tooltip } from 'react-tooltip';

export default function DashboardCreateProjectModal({ show, onHide, auth, handleSubmit,
    title, setTitle,
    address, setAddress,
    description, setDescription
}) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <div className='dashboard-createprojectmodal'>
                <div className='dashboard-createprojectmodal-header-container'>
                    <div className='dashboard-createprojectmodal-header'>
                        Create Project
                    </div>
                    <div className='dashboard-createprojectmodal-close'>
                        <IconX size={24} stroke={2} onClick={onHide} />
                    </div>
                </div>
                <div className='dashboard-createprojectmodal-input-container'>
                    <div className='dashboard-createprojectmodal-input-title'>
                        Title<sup>*</sup>
                    </div>

                    <div>
                        <input
                            type='text'
                            className='dashboard-createprojectmodal-input'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder='Project Title...'
                        />
                    </div>
                </div>

                <PlacesAutocomplete
                    value={address}
                    onChange={(a) => setAddress(a)}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                        <div className='dashboard-createprojectmodal-autocomplete-container'>
                            <div className='dashboard-createprojectmodal-input-container'>
                                <div className='dashboard-createprojectmodal-input-title'>
                                    Address
                                </div>
                                <div className='projectdetails-details-modal-body-row-value'>
                                    <input
                                        {...getInputProps({
                                            type: "text",
                                            name: "address",
                                            placeholder: 'Project Address...'
                                        })}
                                        className='dashboard-createprojectmodal-input'
                                    ></input>
                                </div>
                            </div>

                            {suggestions.length > 0 &&
                                <div className="dashboard-createprojectmodal-autocomplete-dropdown-container">
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'dashboard-createprojectmodal-suggestion-item-active' : 'dashboard-createprojectmodal-suggestion-item';
                                        return (
                                            <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.description}>
                                                {suggestion.description}
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    }
                </PlacesAutocomplete>

                <div className='dashboard-createprojectmodal-input-container'>
                    <div className='dashboard-createprojectmodal-input-title'>
                        Description
                    </div>

                    <div>
                        <textarea
                            type='text'
                            className='dashboard-createprojectmodal-input dashboard-createprojectmodal-description'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder='Project Description...'
                        />
                    </div>
                </div>

                <div className='dashboard-createprojectmodal-button-container'>
                    {title
                        ? <ActiveButton
                            text='Create'
                            handleClick={handleSubmit}
                        >
                            Create
                        </ActiveButton>
                        : <div
                            id='dashboard-createprojectmodal-button-disabled'
                            className='dashboard-createprojectmodal-button-disabled'
                        >
                            Create
                        </div>
                    }
                </div>

                <Tooltip anchorSelect={'#dashboard-createprojectmodal-button-disabled'} place='left' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
                    Please enter a title
                </Tooltip>
            </div>
        </Modal>
    )
}
