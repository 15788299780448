import { useContext, useState } from "react";
import { TakeoffContext } from "../../helper/Context";
import { Line } from "react-konva";
import axios from "axios";
import { API_ROUTE } from "../../..";
import { selectAuth } from "../../../redux/slices/authSlice";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

export default function LengthAssignmentMode({ }) {
    const {
        AILocalization,
        pageID,
        pages,
        AIAutoCountExample, setAIAutoCountExample,
        AIClassAgLength, setAIClassAgLength,
        LengthAssignment, setLengthAssignment,
    } = useContext(TakeoffContext);

    return (
        <>
            {AIClassAgLength && Object.values(AIClassAgLength).filter((line) => !line.measurement).map((line, i) =>
                <LengthAssignmentLine
                    key={i}
                    line={line}
                />
            )}
        </>
    )
}

function LengthAssignmentLine({ line }) {
    const auth = useSelector(selectAuth);

    const {
        AILocalization,
        pageID,
        pages,
        AIAutoCountExample, setAIAutoCountExample,
        AIClassAgLength, setAIClassAgLength,
        LengthAssignment, setLengthAssignment,
        createMeasurements,
        handleChangeFilter,
        setMeasurements,
        groups,
        currentGroup,
        selectedGroups,
    } = useContext(TakeoffContext);

    const [hovering, setHovering] = useState(false);

    const handleCreateLine = () => {
        handleChangeFilter('include_length', true);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'type': 'line',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                'hide': false,
                'size': 2,
                ['line_dots']: [{ 'x': line.x1, 'y': line.y1 }, { 'x': line.x2, 'y': line.y2 }],
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/line/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup || null,
                'groups': [...(currentGroup ? new Set([currentGroup, ...selectedGroups]) : new Set(selectedGroups))],
                'dots': [{ 'x': line.x1, 'y': line.y1 }, { 'x': line.x2, 'y': line.y2 }],
                'AI': true,
                'time': 0.01,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                axios({
                    method: 'put',
                    url: `${API_ROUTE}/api/aiclassaglengthsamlines/${line.id}/`,
                    data: {
                        'page': line.page,
                        'x1': line.x1,
                        'y1': line.y1,
                        'x2': line.x2,
                        'y2': line.y2,
                        'measurement': response.data.measurement.id,
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json"
                    },
                })
                    .then((updateresponse) => {
                        console.log(updateresponse);

                        setAIClassAgLength(prev => ({
                            ...prev,
                            [line.id]: {
                                ...prev[line.id],
                                'measurement': response.data.measurement,
                            }
                        }))
                        createMeasurements(response.data, tempName);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <Line
                points={[line.x1, line.y1, line.x2, line.y2]}
                stroke="blue"
                strokeWidth={2 / pages[pageID].zoom}
            />

            <Line
                points={[line.x1, line.y1, line.x2, line.y2]}
                stroke={"rgba(0, 0, 255)"}
                opacity={hovering ? 0.5 : 0.25}
                strokeWidth={10 / pages[pageID].zoom}
                onClick={() => handleCreateLine()}
                onMouseEnter={(e) => {
                    e.target.getStage().container().style.cursor = "pointer";
                    setHovering(true);
                }}
                onMouseLeave={(e) => {
                    e.target.getStage().container().style.cursor = "default";
                    setHovering(false);
                }}
            />
        </>
    )
}