function containedInRectangle(rectangle1, rectangle2) {
    return rectangle1.x >= rectangle2.x && rectangle1.x <= rectangle2.x + rectangle2.width && rectangle1.y >= rectangle2.y && rectangle1.y <= rectangle2.y + rectangle2.height;
}

function containedInPolygon(point, polygon) {
    let inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x, yi = polygon[i].y;
        const xj = polygon[j].x, yj = polygon[j].y;

        const intersect = ((yi > point.y) !== (yj > point.y))
            && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);

        if (intersect) {
            inside = !inside;
        }
    }

    return !inside;
}

function lineIntersectsLine(line1, line2) {
    const x1 = line1.start.x;
    const y1 = line1.start.y;
    const x2 = line1.end.x;
    const y2 = line1.end.y;

    const x3 = line2.start.x;
    const y3 = line2.start.y;
    const x4 = line2.end.x;
    const y4 = line2.end.y;

    const denominator = ((y4 - y3) * (x2 - x1)) - ((x4 - x3) * (y2 - y1));

    if (denominator === 0) {
        return false;
    }

    const ua = (((x4 - x3) * (y1 - y3)) - ((y4 - y3) * (x1 - x3))) / denominator;
    const ub = (((x2 - x1) * (y1 - y3)) - ((y2 - y1) * (x1 - x3))) / denominator;

    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
    }

    return true;
}

function intersects(selectionArea, measurement, page) {
    const selectionMaxX = Math.max(selectionArea.x / page.zoom, (selectionArea.x + selectionArea.width) / page.zoom);
    const selectionMinX = Math.min(selectionArea.x / page.zoom, (selectionArea.x + selectionArea.width) / page.zoom);
    const selectionMaxY = Math.max(selectionArea.y / page.zoom, (selectionArea.y + selectionArea.height) / page.zoom);
    const selectionMinY = Math.min(selectionArea.y / page.zoom, (selectionArea.y + selectionArea.height) / page.zoom);

    const selectionAreaTop = {
        start: { x: selectionMinX, y: selectionMinY },
        end: { x: selectionMaxX, y: selectionMinY },
    };
    const selectionAreaRight = {
        start: { x: selectionMaxX, y: selectionMinY },
        end: { x: selectionMaxX, y: selectionMaxY },
    };
    const selectionAreaBottom = {
        start: { x: selectionMaxX, y: selectionMaxY },
        end: { x: selectionMinX, y: selectionMaxY },
    };
    const selectionAreaLeft = {
        start: { x: selectionMinX, y: selectionMaxY },
        end: { x: selectionMinX, y: selectionMinY },
    };

    const selectionAreaTopLeft = {
        x: selectionMinX,
        y: selectionMinY,
    };

    const selectionAreaTopRight = {
        x: selectionMaxX,
        y: selectionMinY,
    };

    const selectionAreaBottomRight = {
        x: selectionMaxX,
        y: selectionMaxY,
    };

    const selectionAreaBottomLeft = {
        x: selectionMinX,
        y: selectionMaxY,
    };

    const selectionAreaDots = [selectionAreaTopLeft, selectionAreaTopRight, selectionAreaBottomRight, selectionAreaBottomLeft];

    if (measurement.type === 'count') {
        for (let i = 0; i < measurement.count_dots.length; i++) {
            const dot = measurement.count_dots[i];
            if (dot.x >= selectionMinX && dot.x <= selectionMaxX && dot.y >= selectionMinY && dot.y <= selectionMaxY) {
                return true;
            }
        }

        return false;
    } else if (measurement.type === 'rectangle') {
        const top = {
            start: { x: measurement.rectangle.x, y: measurement.rectangle.y },
            end: { x: measurement.rectangle.x + measurement.width, y: measurement.rectangle.y },
        };
        const right = {
            start: { x: measurement.rectangle.x + measurement.width, y: measurement.rectangle.y },
            end: { x: measurement.rectangle.x + measurement.width, y: measurement.rectangle.y + measurement.height },
        };
        const bottom = {
            start: { x: measurement.rectangle.x + measurement.width, y: measurement.rectangle.y + measurement.height },
            end: { x: measurement.rectangle.x, y: measurement.rectangle.y + measurement.height },
        };
        const left = {
            start: { x: measurement.rectangle.x, y: measurement.rectangle.y + measurement.height },
            end: { x: measurement.rectangle.x, y: measurement.rectangle.y },
        };

        if (lineIntersectsLine(top, selectionAreaTop) || lineIntersectsLine(top, selectionAreaRight) || lineIntersectsLine(top, selectionAreaBottom) || lineIntersectsLine(top, selectionAreaLeft)
            || lineIntersectsLine(right, selectionAreaTop) || lineIntersectsLine(right, selectionAreaRight) || lineIntersectsLine(right, selectionAreaBottom) || lineIntersectsLine(right, selectionAreaLeft)
            || lineIntersectsLine(bottom, selectionAreaTop) || lineIntersectsLine(bottom, selectionAreaRight) || lineIntersectsLine(bottom, selectionAreaBottom) || lineIntersectsLine(bottom, selectionAreaLeft)
            || lineIntersectsLine(left, selectionAreaTop) || lineIntersectsLine(left, selectionAreaRight) || lineIntersectsLine(left, selectionAreaBottom) || lineIntersectsLine(left, selectionAreaLeft)) {
            return true;
        }

        if (containedInRectangle({
            x: measurement.rectangle.x,
            y: measurement.rectangle.y,
            width: Math.abs(measurement.width),
            height: Math.abs(measurement.height),
        }, {
            x: selectionMinX,
            y: selectionMinY,
            width: Math.abs(selectionMaxX - selectionMinX),
            height: Math.abs(selectionMaxY - selectionMinY),
        })) {
            return true;
        }

        if (containedInRectangle({
            x: selectionMinX,
            y: selectionMinY,
            width: Math.abs(selectionMaxX - selectionMinX),
            height: Math.abs(selectionMaxY - selectionMinY),
        }, {
            x: measurement.rectangle.x,
            y: measurement.rectangle.y,
            width: Math.abs(measurement.width),
            height: Math.abs(measurement.height),
        })) {
            return true;
        }

        return false;
    } else if (measurement.type === 'polygon') {
        const lineSegments = [];
        for (let i = 0; i < measurement.polygon_dots.length; i++) {
            const line = {
                start: measurement.polygon_dots[i],
                end: measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length],
            };
            lineSegments.push(line);
        }

        for (let i = 0; i < lineSegments.length; i++) {
            const line = lineSegments[i];

            if (lineIntersectsLine(line, selectionAreaTop) || lineIntersectsLine(line, selectionAreaRight) || lineIntersectsLine(line, selectionAreaBottom) || lineIntersectsLine(line, selectionAreaLeft)) {
                return true;
            }
        }

        let inside = true;
        for (let i = 0; i < measurement.polygon_dots.length; i++) {
            const dot = measurement.polygon_dots[i];
            if (dot.x < selectionMinX || dot.x > selectionMaxX || dot.y < selectionMinY || dot.y > selectionMaxY) {
                inside = false;
                break;
            }
        }

        if (inside) {
            return true;
        }

        let insideCorners = true;

        for (let i = 0; i < selectionAreaDots.length; i++) {
            const dot = selectionAreaDots[i];
            if (containedInPolygon(dot, measurement.polygon_dots)) {
                insideCorners = false;
                break;
            }
        }

        if (insideCorners) {
            return true;
        }

        return false;
    } else if (measurement.type === 'line') {
        const lineSegments = [];
        for (let i = 0; i < measurement.line_dots.length - 1; i++) {
            const line = {
                start: measurement.line_dots[i],
                end: measurement.line_dots[i + 1],
            };
            lineSegments.push(line);
        }

        for (let i = 0; i < lineSegments.length; i++) {
            const line = lineSegments[i];

            if (lineIntersectsLine(line, selectionAreaTop) || lineIntersectsLine(line, selectionAreaRight) || lineIntersectsLine(line, selectionAreaBottom) || lineIntersectsLine(line, selectionAreaLeft)) {
                return true;
            }
        }

        let inside = true;

        for (let i = 0; i < measurement.line_dots.length; i++) {
            const dot = measurement.line_dots[i];
            if (dot.x < selectionMinX || dot.x > selectionMaxX || dot.y < selectionMinY || dot.y > selectionMaxY) {
                inside = false;
                break;
            }
        }

        if (inside) {
            return true;
        }

        return false;
    } else if (measurement.type === 'circle') {
        const center = {
            x: measurement.circle.x,
            y: measurement.circle.y,
        };

        const radius = measurement.circle.radius;

        const inside = selectionAreaDots.some(dot => {
            return Math.pow(dot.x - center.x, 2) + Math.pow(dot.y - center.y, 2) <= Math.pow(radius, 2);
        })

        if (inside) {
            return true;
        }

        if (center.x >= selectionMinX && center.x <= selectionMaxX && center.y >= selectionMinY && center.y <= selectionMaxY) {
            return true;
        }

        let intersect = [selectionAreaTop, selectionAreaRight, selectionAreaBottom, selectionAreaLeft].some(edge => {
            let start = edge.start;
            let end = edge.end;

            const dx = end.x - start.x;
            const dy = end.y - start.y;

            const u = Math.min(1, Math.max(0, ((center.x - start.x) * dx + (center.y - start.y) * dy) / (dx * dx + dy * dy)));
            const nx = start.x + u * dx - center.x;
            const ny = start.y + u * dy - center.y;

            return nx * nx + ny * ny <= radius * radius;
        })

        if (intersect) {
            return true;
        }

        return false;
    }
}

export function getSelectedAILengths(AILines, selectionArea, page) {
    const selectedLines = [];

    Object.values(AILines).forEach(line => {
        if (line.page === page.id && intersects(selectionArea, {
            ...line,
            type: 'line',
            line_dots: [{ x: line.x1, y: line.y1 }, { x: line.x2, y: line.y2 }],
        }, page)) {
            selectedLines.push(line.id);
        }
    });

    return selectedLines;
}

export function getSelected(selectionArea, measurements, page, groups) {
    const selectedMeasurements = [];

    const isHiddenNest = (group) => {
        if (group.hide) {
            return true;
        }

        if (group.parent) {
            return isHiddenNest(groups[group.parent]);
        }

        return false;
    }

    Object.values(measurements).forEach(measurement => {
        if (!measurement.hide && (measurement.group && !isHiddenNest(groups[measurement.group]) || !measurement.group) && measurement.page === page.id && intersects(selectionArea, measurement, page)) {
            selectedMeasurements.push(measurement.id);
        }
    });

    return selectedMeasurements;
}
