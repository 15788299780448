import { IconAspectRatio, IconChevronDown, IconCircleDotted, IconDimensions, IconHighlight, IconLineDashed, IconPointFilled, IconTextSize, IconUnderline, IconX } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { TakeoffContext } from "../../../helper/Context";
import { IconTrashX } from "@tabler/icons-react";
import { Tooltip } from "react-bootstrap";
import DetailsNavbarInput from "../components/DetailsNavbarInput";
import { IconTextColor } from "@tabler/icons-react";
import ColorPicker from "../../../../components/ColorPicker";
import { IconLine } from "@tabler/icons-react";
import Popup from "reactjs-popup";
import { UOM_CONVERSIONS, UOM_Description, UOM_Display } from "../../../helper/UnitConversions";

export default function AnnotationRulerDetails({ left, width }) {
    const {
        pageID,
        project, setProject,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        drawing,
        handleUpdateProject,
        currentMeasurement, setCurrentMeasurement,
        handleCircleTransform,
        setEditingMeasurement,
        setShowDetails,
        setShowDeleteMeasurementModal,
        groupMeasurement,
        optionDown, setOptionDown,
        handleZoomToMeasurement,
        setSelectedMeasurements,
        annotations, setAnnotations,
        currentAnnotation, setCurrentAnnotation,
        handleUpdateAnnotation,
        handleDeleteAnnotation,
    } = useContext(TakeoffContext);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showFillPicker, setShowFillPicker] = useState(false);

    useEffect(() => {
        const handleClick = e => {
            setShowColorPicker(false);
            setShowFillPicker(false);
        }

        const handleKeyDown = e => {
            if (e.key === 'Escape' && showColorPicker) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowColorPicker(false);
                setShowFillPicker(false);
                setCurrentAnnotation(currentAnnotation);
            } else if (e.key === 'Enter' && (showColorPicker || showFillPicker) || e.key === 'Return' && (showColorPicker || showFillPicker)) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowColorPicker(false);
                setShowFillPicker(false);
            }
        }

        window.addEventListener('click', handleClick);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [showColorPicker, showFillPicker]);

    return (
        <>
            <div className='detailsnavbar-buttons-container'>
                <div className='detailsnavbar-title'>
                    Annotation
                </div>

                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <DetailsNavbarInput
                    keyID={'detailsnavbar-circledetails-fontsize'}
                >
                    {width < 900
                        ? <div className='detailsnavbar-row-name'>
                            <IconLineDashed size={20} stroke={1} />
                        </div>
                        : <div className='detailsnavbar-row-name'>
                            <IconLineDashed size={20} stroke={1} />
                            Dash
                        </div>
                    }
                    <input
                        className='detailsnavbar-input'
                        id='detailsnavbar-circledetails-fontsize'
                        type='number'
                        value={annotations[currentAnnotation].size || ''}
                        onFocus={() => setEditingMeasurement(true)}
                        onChange={e => setAnnotations(prev => ({
                            ...prev,
                            [currentAnnotation]: {
                                ...prev[currentAnnotation],
                                size: e.target.value ? e.target.value : null,
                            }
                        }))}
                        onBlur={() => {
                            handleUpdateAnnotation(annotations[currentAnnotation]);
                            setEditingMeasurement(false);
                        }}
                        placeholder='--'
                    />
                </DetailsNavbarInput>


                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <IconLine size={20} stroke={1} />

                <div className='detailsnavbar-color-container'>
                    {showColorPicker
                        ? <ColorPicker
                            color={annotations[currentAnnotation].stroke_color}
                            onChange={(color) => setAnnotations(prev => ({
                                ...prev,
                                [currentAnnotation]: {
                                    ...prev[currentAnnotation],
                                    stroke_color: color.hex,
                                }
                            }))}
                            onChangeComplete={(color) => {
                                handleUpdateAnnotation({
                                    ...annotations[currentAnnotation],
                                    stroke_color: color.hex,
                                });
                            }}
                            top={35}
                            //left={left + 50}
                        />
                        : null}

                    <div
                        className='detailsnavbar-button'
                        id={`detailsnavbar-circle-color`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowColorPicker(true);
                        }}
                    >
                        <div
                            className='detailsnavbar-option-color-placeholder'
                            style={{
                                backgroundColor: annotations[currentAnnotation].stroke_color || '#9DD9F3'
                            }}
                        ></div>
                    </div>
                </div>


                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <IconCircleDotted size={20} stroke={1} />

                <div className='detailsnavbar-color-container'>
                    {showFillPicker
                        ? <ColorPicker
                            color={annotations[currentAnnotation].fill_color}
                            onChange={(color) => setAnnotations(prev => ({
                                ...prev,
                                [currentAnnotation]: {
                                    ...prev[currentAnnotation],
                                    fill_color: color.hex,
                                }
                            }))}
                            onChangeComplete={(color) => {
                                handleUpdateAnnotation({
                                    ...annotations[currentAnnotation],
                                    fill_color: color.hex,
                                });
                            }}
                            top={35}
                            //left={left + 50}
                        />
                        : null}

                    <div
                        className='detailsnavbar-button'
                        id={`detailsnavbar-circle-color`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowFillPicker(true);
                        }}
                    >
                        <div
                            className='detailsnavbar-option-color-placeholder'
                            style={{
                                backgroundColor: annotations[currentAnnotation].fill_color || '#9DD9F3'
                            }}
                        ></div>
                    </div>
                </div>

                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <div
                    style={{
                        zIndex: 10000,
                    }}
                >
                    <Popup
                        trigger={open => (
                            <div
                                id='detailsnavbar-linedetails-uom'
                                className={'detailsnavbar-button'}
                                style={{
                                    color: open ? '#006AFE' : '#525252'
                                }}
                            >
                                <IconDimensions size={20} stroke={1} />
                                {UOM_Display[annotations[currentAnnotation]?.ruler?.uom || 'ft']}
                                <IconChevronDown size={20} stroke={1} />
                            </div>
                        )}
                        on='click'
                        position="bottom center"
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        contentStyle={{
                            width: '250px',
                        }}
                    >
                        <div className="detailsnavbar-uom-items">
                            {(['in', 'ft', 'yrd', 'cm', 'm']).map((uom, i) => (
                                <div
                                    key={i}
                                    className={'detailsnavbar-uom-item ' + ((annotations[currentAnnotation]?.ruler?.uom || 'ft') === uom ? 'detailsnavbar-uom-item-active' : '')}
                                    onClick={() => {
                                        handleUpdateAnnotation({
                                            ...annotations[currentAnnotation],
                                            'ruler': {
                                                ...annotations[currentAnnotation].ruler,
                                                'uom': uom,
                                            }
                                        });
                                    }}
                                >
                                    <div>{UOM_Display[uom]}</div>
                                    <div>({UOM_Description[uom]})</div>
                                    <div>
                                        {'ft' === uom
                                            ? <><IconPointFilled size={20} /></>
                                            : <>&nbsp;</>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Popup>
                </div>

                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-annotation-text-delete`}
                    onClick={() => handleDeleteAnnotation(currentAnnotation)}
                    size={'small'}
                >
                    <IconTrashX size={20} stroke={1} style={{ color: 'red' }} />
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-annotation-text-delete'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                    Delete annotation
                </Tooltip>
            </div>

            <div
                className='detailsnavbar-button'
                id={`detailsnavbar-circle-close`}
                onClick={() => {
                    setCurrentMeasurement(null);
                    setCurrentAnnotation(null);
                }}
            >
                <IconX size={20} stroke={1} />
            </div>
        </>
    )
}