import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image, Arrow, Text } from "react-konva";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../../index";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

export default function AnnotationRulerConstructor({ }) {
    const {
        pageID,
        pages,
        handleCreateAnnotation,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    let slope = 0;
    let distance_x = 0;
    let distance_y = 0;

    let endTic = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
    }

    let startTic = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
    }

    let length = 0;


    if (end?.y && start?.y) {
        slope = (end.y - start.y) / (end.x - start.x);

        distance_x = 10 / pages[pageID].zoom / Math.sqrt(1 + 1 / (slope * slope));
        distance_y = - distance_x / slope;

        if (slope === Infinity || slope === -Infinity) {
            distance_x = 10 / pages[pageID].zoom;
            distance_y = 0;
        } else if (slope === 0) {
            distance_x = 0;
            distance_y = 10 / pages[pageID].zoom;
        }

        endTic = {
            x1: end.x + distance_x,
            y1: end.y + distance_y,
            x2: end.x - distance_x,
            y2: end.y - distance_y,
        }

        startTic = {
            x1: start.x + distance_x,
            y1: start.y + distance_y,
            x2: start.x - distance_x,
            y2: start.y - distance_y,
        }

        length = Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
    }


    return (
        <>
            {(drawing && start && end) ?
                <>
                    {pages[pageID].scale && length && start.x && start.y && end.x && end.y &&
                        <Text
                            x={(start.x + end.x) / 2 + 1.5 * distance_x}
                            y={(start.y + end.y) / 2 + 1.5 * distance_y}
                            text={(length && pages[pageID].scale) ? `${(length * pages[pageID].scale).toFixed(2)} ft` : `No Scale`}
                            fontSize={12 / pages[pageID].zoom}
                            fill={'#006aff'}
                            opacity={0.7}
                        />
                    }

                    {!pages[pageID].scale && start.x && start.y && end.x && end.y &&
                        <Text
                            x={(start.x + end.x) / 2 + 1.5 * distance_x}
                            y={(start.y + end.y) / 2 + 1.5 * distance_y}
                            text={`No Scale`}
                            fontSize={12 / pages[pageID].zoom}
                            fill={'#006aff'}
                            opacity={0.7}
                        />
                    }

                    <Line
                        points={[start.x, start.y, end.x, end.y]}
                        stroke="#006aff"
                        strokeWidth={2 / pages[pageID].zoom}
                        dash={[10 / pages[pageID].zoom, 5 / pages[pageID].zoom]}
                    />

                    <Circle
                        x={start.x}
                        y={start.y}
                        radius={((start.x - end.x) ** 2 + (start.y - end.y) ** 2) ** 0.5}
                        opacity={0.5}
                        stroke={'#006aff'}
                        strokeWidth={1 / pages[pageID].zoom}
                        dash={[10 / pages[pageID].zoom, 2 / pages[pageID].zoom]}
                    />

                    {startTic.x1 &&
                        <>
                            <Line
                                points={[endTic.x1, endTic.y1, endTic.x2, endTic.y2]}
                                stroke="#006aff"
                                strokeWidth={2 / pages[pageID].zoom}
                            />

                            <Line
                                points={[startTic.x1, startTic.y1, startTic.x2, startTic.y2]}
                                stroke="#006aff"
                                strokeWidth={2 / pages[pageID].zoom}
                            />
                        </>
                    }
                </>
                : null
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"}
                onClick={(e) => {
                    if (e.evt.button !== 0) return;

                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        handleCreateAnnotation({
                            type: "ruler",
                            data: {
                                x1: start.x,
                                y1: start.y,
                                x2: x,
                                y2: y,
                            }
                        })
                        setStart(null);
                        setEnd(null);
                        setDrawing(false);
                    } else {
                        setDrawing(true);
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setStart({ x: x, y: y });
                    }
                }}
                onMouseMove={(e) => {
                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setEnd({ x: x, y: y });
                    }
                }}
            />
        </>
    );
}