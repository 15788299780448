import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { IconArrowLeft, IconArrowRight, IconDownload, IconTrashX, IconUpload, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useDropzone } from "react-dropzone";
import TextareaAutosize from 'react-textarea-autosize';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import { selectAuth } from '../redux/slices/authSlice';
import { API_ROUTE, WEBSOCKET_ROUTE } from '..';

import ProjectDetailsNavbar from './components/ProjectDetailsNavbar';
import DeleteButton from '../components/DeleteButton';
import DefaultButton from '../components/DefaultButton';
import SidebarFile from './components/SidebarFile';

import './styles/ProjectDetails.css';
import ProjectDetailsPage from './components/ProjectDetailsPage';
import { Blocks } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';
import ActiveButton from '../components/ActiveButton';

export default function ProjectDetailsModal({ project, setProject, showProjectDetails, setShowProjectDetails }) {
    const auth = useSelector(selectAuth);

    const handleSaveProjectAddress = (lat, lng, address) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: {
                author: project.author,
                company: project.company,
                title: project.title,
                address: address,
                desc: project.desc,
                lat: lat,
                lng: lng,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleUpdateProject = () => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: {
                author: project.author,
                company: project.company,
                title: project.title,
                address: project.address,
                desc: project.desc,
                lat: project.lat,
                lng: project.lng,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Modal
            show={showProjectDetails}
            onHide={() => setShowProjectDetails(false)}
        >
            <div className='dashboard-createprojectmodal'>
                <div className='dashboard-createprojectmodal-header-container'>
                    <div className='dashboard-createprojectmodal-header'>
                        Project Details
                    </div>
                    <div className='dashboard-createprojectmodal-close'>
                        <IconX size={24} stroke={2} onClick={() => setShowProjectDetails(false)} />
                    </div>
                </div>
                <div className='dashboard-createprojectmodal-input-container'>
                    <div className='dashboard-createprojectmodal-input-title'>
                        Title<sup>*</sup>
                    </div>

                    <div>
                        <input
                            type='text'
                            className='dashboard-createprojectmodal-input'
                            placeholder='Project Title...'
                            value={project?.title || ''}
                            onChange={(e) => {
                                setProject(prev => ({
                                    ...prev,
                                    title: e.target.value || ''
                                }))
                            }}
                            onBlur={() => handleUpdateProject()}
                        />
                    </div>
                </div>

                <PlacesAutocomplete
                    value={project?.address}
                    onChange={(a) => setProject(prev => ({ ...prev, ['address']: a }))}
                    onSelect={(a) => {
                        geocodeByAddress(a)
                            .then(results => getLatLng(results[0]))
                            .then(latLng => {
                                console.log('Success', latLng);

                                setProject(prev => ({
                                    ...prev,
                                    address: a,
                                    lat: latLng.lat,
                                    lng: latLng.lng,
                                }));
                                handleSaveProjectAddress(latLng.lat, latLng.lng, a);
                            })
                            .catch(error => console.error('Error', error));
                    }}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                        <div className='dashboard-createprojectmodal-autocomplete-container'>
                            <div className='dashboard-createprojectmodal-input-container'>
                                <div className='dashboard-createprojectmodal-input-title'>
                                    Address
                                </div>
                                <div className='projectdetails-details-modal-body-row-value'>
                                    <input
                                        {...getInputProps({
                                            type: "text",
                                            name: "address",
                                            placeholder: "Project address...",
                                        })}
                                        className='dashboard-createprojectmodal-input'
                                        onBlur={() => handleUpdateProject()}
                                    ></input>
                                </div>
                            </div>

                            {suggestions.length > 0 &&
                                <div className="dashboard-createprojectmodal-autocomplete-dropdown-container">
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'dashboard-createprojectmodal-suggestion-item-active' : 'dashboard-createprojectmodal-suggestion-item';
                                        return (
                                            <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.description}>
                                                {suggestion.description}
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    }
                </PlacesAutocomplete>

                <div className='dashboard-createprojectmodal-input-container'>
                    <div className='dashboard-createprojectmodal-input-title'>
                        Description
                    </div>
                    <TextareaAutosize
                        className='dashboard-createprojectmodal-input dashboard-createprojectmodal-description'
                        value={project?.desc}
                        onChange={(e) => setProject(prev => ({ ...prev, ['desc']: e.target.value || '' }))}
                        onBlur={() => handleUpdateProject()}
                        minRows={3}
                        maxRows={10}
                        placeholder='Project description...'
                    />
                </div>

                <div className='dashboard-createprojectmodal-button-container'>
                    <ActiveButton
                        text='Save'
                        handleClick={() => setShowProjectDetails(false)}
                    >
                        Save
                    </ActiveButton>
                </div>
            </div>
        </Modal >
    )
}